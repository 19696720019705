import MotionFade from "../../../common/utils/MotionFade";
import {useCurrencies, useGetThirdConfig} from "../../../../core/services/react-query/setting";
import {CFlex, CMargin, DText, Flex, Padding} from "../../../../styles/CommonStyles";
import Text from "../../../../core/utils/Text";
import Select from "../../../common/dropdown/Select";
import Input from "../../../common/input/Input";
import UpdateCoinTag from "./elements/UpdateCoinTag";
import CheckBox from "../../../common/input/CheckBox";
import {useEffect, useState} from "react";
import {UpdateCoinTagWrapper} from "../../../../styles/main/setting";


const OtcUpdate = ({
    data,
    onDetailsChange,
    onCoinStateChange,
    changeAllSelectedState,
    baseInputError
}) => {

    const { data: thirdConfig } = useGetThirdConfig()
    const { data: availableCoins } = useCurrencies()

    const [allSelected, setAllSelected] = useState(false)

    useEffect(() => {
        if (availableCoins && data) {
            let _allSelected = true
            for (let i = 0; i < availableCoins.length; i++) {
                const coin = availableCoins[i]
                if (!data.third.coins.includes(coin.id)) {
                    _allSelected = false
                    break
                }
            }
            setAllSelected(_allSelected)
        }
    }, [availableCoins, data])


    return (
        <MotionFade>
            <CFlex width={'300px'} align={'flex-start'}>
                <Padding padding={'0 4px'}>
                    <DText primary fontSize={'s'}>
                        <Text tid={'default'} />
                    </DText>
                    <CMargin margin={'4px'} />
                    <Select
                        options={thirdConfig?.default}
                        value={data?.third?.default}
                        placeHolder={'select-third-default'}
                        onValueChange={(idx) => onDetailsChange(thirdConfig?.default[idx], 'default')}
                        size={'small'}
                        width={'100%'}
                        height={'40px'}
                    />
                </Padding>
                <CMargin margin={'8px'} />

                <Padding padding={'0 4px'}>
                    <DText primary fontSize={'s'}>
                        <Text tid={'active-transactions'} />
                    </DText>
                    <CMargin margin={'4px'} />
                    <Select
                        options={thirdConfig?.on}
                        value={data?.third?.on}
                        placeHolder={'select-third-on'}
                        onValueChange={(idx) => onDetailsChange(thirdConfig?.on[idx], 'on')}
                        size={'small'}
                        width={'100%'}
                        height={'40px'}
                    />
                </Padding>

                <CMargin margin={'8px'} />
                <DText primary fontSize={'s'} style={{padding: '0 4px'}}>
                    <Text tid={'otc-base'} />
                </DText>
                <Input
                    value={data?.third?.base}
                    onInputChange={(v) => onDetailsChange(v, 'base')}
                    placeHolder={'otc-base'}
                    size={'small'}
                    error={baseInputError && 'base-input-mandatory'}
                    normal={!baseInputError}
                    valid={!baseInputError}
                />
            </CFlex>
            <DText primary fontSize={'s'} style={{padding: '0 4px'}}>
                <Text tid={'active-coins'} /> :
            </DText>
            <CMargin margin={'8px'} />
            <Flex fw justify={'flex-start'}>
                <UpdateCoinTagWrapper active={allSelected}>
                    <Flex fw fh justify={'space-between'}>
                        <CheckBox
                            active={allSelected}
                            onClick={() => changeAllSelectedState(allSelected)}
                        />
                        <DText main style={{ margin: '0 10px'}}>
                            <Text tid={'select-all'} />
                        </DText>
                    </Flex>
                </UpdateCoinTagWrapper>
            </Flex>
            <CMargin margin={'8px'} />
            <Flex fw wrap justify={'flex-start'}>
                {availableCoins?.map(coin => (
                    <UpdateCoinTag
                        coin={coin.id}
                        activeCoins={data?.third?.coins}
                        onCoinStateChange={onCoinStateChange}
                    />
                ))}
            </Flex>
        </MotionFade>
    )
}

export default OtcUpdate
