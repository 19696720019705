import {useEffect, useState} from "react";
import {ProfileInfoAnimate, ProfileInfoBody, ProfileInfoTag} from "../../../styles/main/profile";
import {fadeVariants} from "../../../core/utils/theme";
import useGetProfileOrUser from "../../../core/hooks/main/users/useGetProfileOrUser";
import {useMainContext} from "../../../core/contexts/main";
import {useGetRoleQuery} from "../../../core/services/react-query/setting/role";
import {useWindowSize} from "../../../core/hooks/common/useWindowSize";
import {CMargin, Decoration, DText, Flex, Padding} from "../../../styles/CommonStyles";
import Text from "../../../core/utils/Text";
import {formatDate} from "../../../core/utils/common";



const ProfileInfo = ({
    detail = false,
    user,
    refetch
}) => {


    const { width } = useWindowSize()
    const { lang, theme } = useMainContext()
    const { profile } = useGetProfileOrUser(detail, user)

    const [roleId, setRoleId] = useState(null)
    const { data: role, refetch: getRole } = useGetRoleQuery(roleId, false)

    useEffect(() => {

        if (profile?.role && profile?.role !== 'admin' && profile?.role !== 'user') {
            setRoleId(profile.role)
        }

    }, [profile])

    useEffect(() => {
        if (roleId) {
            getRole()
        }
    }, [roleId])


    return (
        <ProfileInfoAnimate
            variants={fadeVariants}
            animate='in'
            exit='out'
            initial='out'
        >
            <CMargin margin={'10px'} />
            <Flex fw justify={'flex-start'}>
                <Decoration />
                <DText fontSize={'s'} main>
                    <Text tid={profile?.isActive ? 'active-user-note' : 'inactive-user-note'} />
                </DText>
            </Flex>
            <CMargin margin={'10px'} />
            <ProfileInfoBody>
                <ProfileItem prefix={'email'} suffix={profile?.email} />
                <ProfileItem prefix={'user-code'} suffix={profile?.code} />
                <ProfileItem prefix={'ga'} suffix={profile?.ga?.status} />
                <ProfileItem prefix={'ga-key'} suffix={profile?.ga?.key} />
                <ProfileItem prefix={'user-affiliate'} suffix={profile?.affiliate} />
                <ProfileItem prefix={'user-type'} suffix={profile?.type} />
                <ProfileItem prefix={'user-role'} suffix={role ? role.name : profile?.role} />
                <ProfileItem prefix={'createdAt'} suffix={profile?.createdAt} date />
                <ProfileItem prefix={'updatedAt'} suffix={profile?.modifiedAt} date />
            </ProfileInfoBody>
        </ProfileInfoAnimate>
    )
}

const ProfileItem = ({prefix, suffix, date = false}) => {

    const { lang } = useMainContext()

    return (
        <ProfileInfoTag>
            <Flex>
                <DText main fontSize={'s'}>
                    <Text tid={prefix} />:
                </DText>
                <DText primary margin={'0 8px'} lineBreak={'anywhere'} fontSize={'s'}>
                    { date ?
                        `${formatDate(suffix, 'date', lang)}-${formatDate(suffix, 'time', lang)}`
                        :
                        <Text tid={suffix} />
                    }
                </DText>
            </Flex>
        </ProfileInfoTag>
    )
}

export default ProfileInfo
