import {useQuery} from "react-query";
import {normalFetch} from "../../fetch-api/get";


const THIRD_KEYS = {
    THIRD_BALANCES: 'get-balances',
    THIRD_ORDER_HISTORY: 'third-order-history'
}


const useGetThirdBalances = (third) => {

    return useQuery(
        THIRD_KEYS.THIRD_BALANCES, () => normalFetch(`thirdparty/${third}/balance`),
        {
            select: res => res?.data?.data,
            enabled: false
        }
    )
}


const useGetThirdOrderHistory = (third) => {
    return useQuery(
        THIRD_KEYS.THIRD_ORDER_HISTORY, () => normalFetch(`thirdparty/${third}/orders`),
        {
            select: res => res?.data,
            enabled: false
        }
    )
}


export {
    useGetThirdBalances,
    useGetThirdOrderHistory
}
