import { useState } from "react"
import {CMargin, Column, DText, Flex, Row} from "../../../../../styles/CommonStyles"
import Tabbar from "../../../../common/tabs/Tabbar"
import TableLayout from "../../../../layout/main/TableLayout"
import {TABLET_SIZE} from "../../../../../core/constants/common"
import { useWindowSize } from "../../../../../core/hooks/common/useWindowSize"
import { TradeTypeBadge } from "../../../../../styles/main/orders"
import SettingRow from "../../../../common/common/SettingRow"
import { getTradeTypeColor } from "../../../../../core/utils/theme"
import PriceNumber from "../../../../common/utils/PriceNumber"
import {availableCoinsWDListAttributes} from "../../../../../core/constants/headers";
import {SOCKET_URL} from "../../../../../core/constants/urls";
import BooleanIcon from "../../../../common/utils/BooleanIcon";


const NetworkBox = ({
    coin
}) => {


    const {width} = useWindowSize()
    const tabs = ['deposit', 'withdraw']
    const [activeTab, setActiveTab] = useState(1)
    const { cs, headers } = availableCoinsWDListAttributes

    return (
        <>
            <CMargin margin='10px'/>
            <Tabbar
                active={activeTab}
                tabs={tabs}
                onTabClicked={(idx) => setActiveTab(idx)}
            />
            {width > TABLET_SIZE ?
                <TableLayout
                    headers={headers}
                    cs={cs}
                    data={{data: coin ? { data: coin[tabs[activeTab - 1]] } : [], loading: false}}
                    hasPagination={false}
                >
                    <DesktopTable
                        data={coin && coin[tabs[activeTab - 1]]}
                        coin={coin}
                    />
                </TableLayout>
                :
                <MobileTable
                    data={coin && coin[tabs[activeTab - 1]]}
                    coin={coin}
                />
            }
        </>
    )
}


const DesktopTable = ({
    data,
    coin
}) => {

    const { cs } = availableCoinsWDListAttributes

    return (
        <>
            {data?.map((item, idx) => (
                    <Row index={idx} cs={cs} key={item.network}>
                        <Column>
                            {idx + 1}
                        </Column>
                        <Column>
                            <Flex>
                                <img
                                    src={SOCKET_URL + item.icon}
                                    alt={item.name}
                                    width={'24px'}
                                />
                                <DText primary margin={'0 5px'}>
                                    {item.name?.toUpperCase()}
                                </DText>
                            </Flex>
                        </Column>
                        <Column>
                            <TradeTypeBadge color={getTradeTypeColor('limit')}>
                                {item.network}
                            </TradeTypeBadge>
                        </Column>
                        <Column>
                            <PriceNumber
                                num={item.min}
                                coin={item.name}
                            />
                        </Column>
                        <Column>
                            <PriceNumber
                                num={item.max}
                                coin={item.name}
                            />
                        </Column>
                        <Column>
                            {item.feeFactor} %
                        </Column>
                        <Column>
                            <PriceNumber
                                num={item.feeMin}
                                coin={item.name}
                            />
                        </Column>
                        <Column>
                            <PriceNumber
                                num={item.feeMax}
                                coin={item.name}
                            />
                        </Column>
                        <Column>
                            <DText primary fontSize={'s'}>
                                {item.delay}
                            </DText>
                        </Column>
                        <Column>
                            <BooleanIcon bool={item.isActive}/>
                        </Column>
                    </Row>
                ))}
        </>
    )
}


const MobileTable = ({
    data,
    coin
}) => {

    return (
        <>
            {data?.map((item, idx) => (
                <Row index={idx} cs='100%' key={item.network}>
                    <SettingRow
                        size={'small'}
                        prefix='name'
                        suffix={
                            <Flex>
                                <img
                                    src={SOCKET_URL + item.icon}
                                    alt={item.name}
                                    width={'24px'}
                                />
                                <DText primary margin={'0 5px'}>
                                    {item.name?.toUpperCase()}
                                </DText>
                            </Flex>
                        }
                    />
                    <SettingRow
                        size={'small'}
                        prefix='network'
                        suffix={
                            <TradeTypeBadge color={getTradeTypeColor('limit')}>
                                {item.network}
                            </TradeTypeBadge>
                        }
                    />
                    <SettingRow
                        size={'small'}
                        prefix='max'
                        suffix={item.min}
                        coin={item.name}
                        priceNumber
                    />
                    <SettingRow
                        size={'small'}
                        prefix='max'
                        suffix={item.max}
                        coin={item.name}
                        priceNumber
                    />
                    <SettingRow
                        size={'small'}
                        prefix='fee-factor'
                        suffix={item.feeFactor}
                    />
                    <SettingRow
                        size={'small'}
                        prefix='fee-max'
                        suffix={item.feeMax}
                        coin={item.name}
                        priceNumber
                    />
                    <SettingRow
                        size={'small'}
                        prefix='delay'
                        suffix={item.delay}
                    />
                    <SettingRow
                        size={'small'}
                        prefix='isActive'
                        suffix={item.isActive}
                        isRadio
                    />
                </Row>
            ))}
        </>
    )
}


export default NetworkBox
