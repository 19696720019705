import useUsersList from "../../../../core/hooks/main/users/useUsersList";
import RTableLayout from "../../layout/RTableLayout";
import {CFlex, CMargin, DText, Flex, Row} from "../../../../styles/CommonStyles";
import Text from "../../../../core/utils/Text";
import {formatNumber} from "../../../../core/utils/common";
import {DocumentStatus, UserStatus} from "../../../../styles/main/profile";
import Avatar from "../../../common/utils/Avatar";
import Operation from "../../../common/utils/Operation";
import {acceptUserOperations, rejectUserOperations} from "../../../../core/constants/operation";


const RUserTable = ({
    data
}) => {

    const { data: users } = data

    const {
        hasWriteAccess,
        onOptionClicked,
        onDetailsClicked
    } = useUsersList()

    return (
        <RTableLayout
            data={data}
        >
            {users?.data?.map((user, idx) => {

                let FULLNAME = user.firstName ? `${user.firstName} ` : ''
                FULLNAME += user.lastName ? user.lastName : ''

                const DOCUMENT = !!user.meliCard && !!user.selfi
                const STATUS = !!user.verifyAt
                const NAMEAVATAR =
                    user?.firstName ? user.firstName : (
                        user?.email ? user?.email : 'U'
                    )

                return (
                    <>
                        <Row cs={'50% 50%'} index={idx} key={user._id}>
                            <Avatar
                                name={NAMEAVATAR}
                                avatar={user.avatar}
                            />
                            <Flex justify={'flex-end'}>
                                <Operation
                                    options={user.verifyAt ? rejectUserOperations : acceptUserOperations}
                                    onOptionClicked={(idx) => onOptionClicked(idx, user)}
                                    onDetailsClicked={() => onDetailsClicked(user)}
                                    hasWriteAccess={hasWriteAccess}
                                    hasDetails
                                />
                            </Flex>

                            <CFlex align={'flex-start'} justify={'space-around'} height={'140px'}>
                                <DText fontSize={'ss'} main>
                                    <Text tid={'fullname'} /> :
                                </DText>
                                <DText fontSize={'ss'} main>
                                    <Text tid={'meliCode'} /> :
                                </DText>
                                <DText fontSize={'ss'} main>
                                    <Text tid={'mobile'} /> :
                                </DText>
                                <DText fontSize={'ss'} main>
                                    <Text tid={'balance'} /> :
                                </DText>
                                <DText fontSize={'ss'} main>
                                    <Text tid={'email'} />
                                </DText>
                                <DText fontSize={'ss'} main>
                                    <Text tid={'user-status'} /> :
                                </DText>
                                <CMargin margin={'10px'} />
                                <DText fontSize={'ss'} main>
                                    <Text tid={'documents-status'} /> :
                                </DText>
                            </CFlex>
                            <CFlex align={'flex-start'} justify={'space-around'} height={'140px'}>
                                <DText fontSize={'ss'} primary>
                                    {FULLNAME ? FULLNAME : '--'}
                                </DText>
                                <DText fontSize={'ss'} primary>
                                    {user.meliCode ? user.meliCode : '--'}
                                </DText>
                                <DText fontSize={'ss'} primary>
                                    {user.mobile ? user.mobile : '--'}
                                </DText>
                                <DText fontSize={'ss'} primary>
                                    {formatNumber(user.balance)}
                                </DText>
                                <DText fontSize={'ss'} primary>
                                    {user.email ? user.email : '--'}
                                </DText>
                                <UserStatus active={STATUS}>
                                    <Text tid={STATUS ? 'verified' : 'not-verified'} />
                                </UserStatus>
                                <CMargin margin={'4px'} />
                                <DocumentStatus active={DOCUMENT}>
                                    <Text tid={DOCUMENT ? 'complete-document' : 'incomplete-document'} />
                                </DocumentStatus>
                            </CFlex>
                        </Row>
                    </>

                )
            })}
        </RTableLayout>
    )
}

export default RUserTable
