import { useState } from "react"
import { useQueryContext } from "../../core/contexts/query"
import Text from "../../core/utils/Text"
import { Background, CFlex, CMargin, Decoration, DText, Flex } from "../../styles/CommonStyles"
import BasicModalActionButton from "../common/buttons/BasicModalActionButton"
import Input from "../common/input/Input"
import CheckBox from "../common/input/CheckBox";


const AddNetworkModal = ({
    details,
    onSubmit,
    onCancel
}) => {

    const {setToast} = useQueryContext()
    const {type, coin} = details

    const initialState = {
        name: '',
        network: '',
        min: 0,
        max: 0,
        feeFactor: 0,
        feeMax: 0,
        delay: '',
        isActive: false
    }
    const [network, setNetwork] = useState(initialState)

    const onNetworkInputChange = (v, type) => {
        setNetwork(state => ({...state, [type]: v}))
    }

    const onSubmitClicked = () => {
        const valid = network.network
        if (valid) onSubmit(network)
        else setToast({
            isError: true, show: true,
            message: 'fill-input-errors'
        })
    }


    return (
        <CFlex fw align='flex-start'>
            <Background bg='mainBg'>
                <Flex fw justify={'space-between'}>
                    <Flex>
                        <Decoration />
                        <DText main>
                            <Text tid={`add-${type}`} />
                        </DText>
                    </Flex>
                    <Flex>
                        <DText main fontSize={'s'} margin={'0 8px'}>
                            <Text tid={'is-active'} /> :
                        </DText>
                        <CheckBox active={network.isActive} onClick={() => onNetworkInputChange(!network.isActive, 'isActive')} />
                    </Flex>
                </Flex>
                <CMargin margin='10px' />
                <Input
                    value={network.name}
                    label={'name'}
                    onInputChange={(v) => onNetworkInputChange(v, 'name')}
                    normal
                />
                <Input
                    value={network.network}
                    label={'network'}
                    onInputChange={(v) => onNetworkInputChange(v, 'network')}
                    normal
                />
                <Input
                    value={network.min}
                    label={'min'}
                    onInputChange={(v) => onNetworkInputChange(v, 'min')}
                    currency={network.name}
                    number
                    normal
                />
                <Input
                    value={network.max}
                    label={'max'}
                    onInputChange={(v) => onNetworkInputChange(v, 'max')}
                    currency={network.name}
                    number
                    normal
                />
                <Input
                    value={network.feeFactor}
                    label={'fee-factor'}
                    onInputChange={(v) => onNetworkInputChange(v, 'feeFactor')}
                    number
                    normal
                />
                <Input
                    value={network.feeMax}
                    label={'fee-max'}
                    onInputChange={(v) => onNetworkInputChange(v, 'feeMax')}
                    currency={network.name}
                    number
                    normal
                />
                <Input
                    value={network.delay}
                    label={'delay'}
                    onInputChange={(v) => onNetworkInputChange(v, 'delay')}
                    normal
                />
                <BasicModalActionButton
                    onSubmit={onSubmitClicked}
                    onCancel={onCancel}
                />
            </Background>
        </CFlex>
    )
}


export default AddNetworkModal
