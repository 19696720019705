import { useState } from "react";
import SettingDetailsLayout from "../../../components/layout/main/SettingDetailLayout";
import AvailableCoinsDetail from "../../../components/main/setting/available-coins/AvailableCoinsDetail";
import {useCurrencies} from "../../../core/services/react-query/setting";


const Currencies = () => {

    const initialState = { data: null, loading: true }
    const [data, setData] = useState(initialState)
    const [coin, setCoin] = useState(null)

    const onQuerySuccess = (res) => setData(res)


    return (
        <SettingDetailsLayout
            query={useCurrencies}
            onQuerySuccess={onQuerySuccess}
        >
            <AvailableCoinsDetail
                data={data}
                coinState={{coin, setCoin}}
            />
        </SettingDetailsLayout>
    )
}

export default Currencies;
