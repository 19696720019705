import {createContext, useContext, useEffect, useState} from "react";
import {MutationCache, QueryCache, QueryClient} from "react-query";
import {useRefreshToken} from "../services/react-query/auth";
import {useMainContext} from "./main";


const context = createContext()

export const useQueryContext = () => {
    return useContext(context)
}


const QueryContextProvider = ({ children }) => {

    const [queryClient, setQueryClient] = useState(new QueryClient())
    const [toast, setToast] = useState({
        show: false, message: "", isError: null
    })

    const [queryCache, setQueryCache] = useState(null)

    const { refreshToken } = useMainContext()
    // const { mutate: revokeToken } = useRefreshToken()

    useEffect(() => {
        const _queryCache = new QueryCache({
            onError: (res) => {
                if (res.response.status === 401) {
                    // revokeToken({ refreshToken })
                } else {
                    setToast({
                        message: res.response.data.message,
                        show: true,
                        isError: true
                    })
                }
            }
        })
        setQueryCache(_queryCache)
    }, [refreshToken])

    useEffect(() => {
        if (queryCache) {
            const client = new QueryClient({
                defaultOptions: {
                    queries: {
                        refetchOnWindowFocus: false,
                        select: res => res.data,
                        retry: false
                    }
                },
                queryCache: queryCache,
                mutationCache: new MutationCache({
                    onError: (res) => {
                        setToast({
                            message: res.response.data.message,
                            show: true,
                            isError: true
                        })
                    }
                })
            })
            setQueryClient(client)
        }
    }, [queryCache])

    return (
        <context.Provider
            value={{
                queryClient,
                toast,
                setToast,
                queryCache
            }}
        >
            {children}
        </context.Provider>
    )
}

export default QueryContextProvider
