import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/css/index.css';
import './styles/css/calendar.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./core/i18n";
import QueryContextProvider from "./core/contexts/query";
import MainContextProvider from "./core/contexts/main";




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <MainContextProvider>
        <QueryContextProvider>
            <App />
        </QueryContextProvider>
    </MainContextProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
