import ListLayout from "../../../components/layout/main/ListLayout";
import {PageCard} from "../../../styles/CommonStyles";
import {useWindowSize} from "../../../core/hooks/common/useWindowSize";
import {useState} from "react";
import FilterLayout from "../../../components/layout/filter/FilterLayout";
import {blockedFilterOptions, CacheKeys} from "../../../core/constants/filter";
import {TABLET_SIZE} from "../../../core/constants/common";
import {useListBlockedUsers} from "../../../core/services/react-query/report/blocked-users";
import BlockedUsersTable from "../../../components/main/reports/blocked-users/BlockedUsersTable";
import RBlockedUsersTable from "../../../components/responsive/main/reports/blocked-users/RBlockedUsersTable";


const BlockedUsers = () => {


    const { width } = useWindowSize()

    const [blocked, setBlocked] = useState({loading: true, data: null})
    const onUserQuery = res => setBlocked(res)

    const Component = width > TABLET_SIZE ? BlockedUsersTable : RBlockedUsersTable

    return (
        <ListLayout>
            <PageCard>
                <FilterLayout
                    query={useListBlockedUsers}
                    options={blockedFilterOptions}
                    onQuerySuccess={onUserQuery}
                    cache={CacheKeys.BLOCKED}
                    // sortOptions={contractSortOptions}
                >
                    <Component data={blocked} />
                </FilterLayout>
            </PageCard>
        </ListLayout>
    )
}

export default BlockedUsers
