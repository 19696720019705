import {CFlex, Flex} from "../../../../styles/CommonStyles";
import DetailCard from "../../../layout/main/details/DetailCard";
import {useGetAUser} from "../../../../core/services/react-query/user";
import {useEffect} from "react";
import DetailItem from "../../../layout/main/details/DetailItem";
import UserTag from "../../../common/utils/UserTag";
import BooleanIcon from "../../../common/utils/BooleanIcon";
import ContractMembers from "./ContractMembers";
import {useGetContractPayments} from "../../../../core/services/react-query/report/contract";
import {TABLET_SIZE} from "../../../../core/constants/common";
import PaymentTable from "../payment/PaymentTable";
import RPaymentTable from "../../../responsive/main/reports/payment/RPaymentTable";
import {useWindowSize} from "../../../../core/hooks/common/useWindowSize";
import Loader from "../../../common/loading/Loader";


const ContractDetailView = ({ contract }) => {

    const { width } = useWindowSize()
    const { data: creator, refetch: getCreator } = useGetAUser(contract?.createdBy)
    const { data: payments, refetch: getPayments, isLoading } = useGetContractPayments(contract?._id)

    useEffect(() => {
        if (contract) {
            getCreator()
            getPayments()
        }
    }, [contract])

    const Component = width > TABLET_SIZE ? PaymentTable : RPaymentTable

    return (
        <CFlex fw>
            {/* details */}
            <DetailCard
                header={'details'}
                date={contract?.createdAt}
            >
                <Flex fw wrap justify={'space-between'}>
                    <DetailItem prefix={'creator'} suffix={<UserTag user={creator} />} />
                    <DetailItem prefix={'is-active'} suffix={<BooleanIcon bool={contract?.isActive} />} />
                    <DetailItem prefix={'is-delete'} suffix={<BooleanIcon bool={contract?.isDelete} />} />
                </Flex>
                {contract?.description &&
                    <DetailItem prefix={'description'} suffix={contract?.description} />
                }
            </DetailCard>

            <Flex fw justify={'space-between'} align={'stretch'} wrap>
                {/* senders */}
                <DetailCard
                    header={'senders'}
                    width={'49%'}
                >
                    <ContractMembers members={contract?.senders} />
                </DetailCard>

                {/* receivers */}
                <DetailCard
                    header={'receivers'}
                    width={'49%'}
                >
                    <ContractMembers members={contract?.receivers} />
                </DetailCard>
            </Flex>

            <DetailCard
                header={'payments'}
            >
                {(isLoading || !payments) ?
                    <Flex fw height={'300px'}>
                        <Loader />
                    </Flex>
                    :
                    <Component data={{ data: payments }} />
                }
            </DetailCard>
        </CFlex>
    )
}

export default ContractDetailView
