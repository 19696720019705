import TableLayout from "../../../layout/main/TableLayout";
import {useAclContext} from "../../../../core/contexts/acl";
import {Column, DeleteIcon, DText, IconWrapper, Row} from "../../../../styles/CommonStyles";
import useGetTableIndex from "../../../../core/hooks/layout/useGetTableIndex";
import PermissionColumn from "./PermissionColumn";
import DateTime from "../../../common/utils/DateTime";
import {EditIcon} from "../../../../styles/main/setting";
import Tooltip from "../../../common/utils/Tooltip";
import {roleAttributes} from "../../../../core/constants/headers";
import {useEffect, useState} from "react";
import ModalLayout from "../../../layout/main/ModalLayout";
import BasicModal from "../../../modals/BasicModal";
import {useDeleteRole} from "../../../../core/services/react-query/setting/role";


const RolesTable = ({
    data,
    enterEditMode
}) => {

    const { data: roles } = data
    const { headers, cs } = roleAttributes
    const {getTableIndex} = useGetTableIndex()
    const {permissions} = useAclContext()
    const hasWriteAccess = permissions?.role?.write

    const initialDeleteMode = { open: false, item: null }
    const [deleteMode, setDeleteMode] = useState(initialDeleteMode)
    const enterDeleteMode = (item) => setDeleteMode({ open: true, item })
    const exitDeleteMode = () => setDeleteMode(initialDeleteMode)

    const { mutate: deleteRole, isLoading: deletingRole, isSuccess: deleteSuccess } = useDeleteRole()
    const onDeleteRole = () => deleteRole(deleteMode.item?._id)
    useEffect(() => {
        if (deleteSuccess) exitDeleteMode()
    }, [deleteSuccess])


    return (
        <TableLayout
            data={data}
            headers={headers}
            cs={cs}
            hasWriteAccess={hasWriteAccess}
        >
            {roles?.data?.map((role, idx) => (
                <Row index={idx} cs={cs} noHover>
                    <Column>
                        {getTableIndex(idx)}
                    </Column>
                    <Column>
                        <DText main fontSize={'s'}>
                            {role.name}
                        </DText>
                    </Column>
                    <Column>
                        <PermissionColumn perms={role.permissions}/>
                    </Column>
                    <Column center>
                        <DateTime dt={role.createdAt}/>
                    </Column>
                    <Column center>
                        <Tooltip
                            content={'edit-role'}
                        >
                            <IconWrapper onClick={() => enterEditMode(role)}>
                                <EditIcon size={22}/>
                            </IconWrapper>
                        </Tooltip>
                        <Tooltip
                            content={'delete-role'}
                        >
                            <IconWrapper onClick={() => enterDeleteMode(role)}>
                                <DeleteIcon size={22}/>
                            </IconWrapper>
                        </Tooltip>
                    </Column>
                </Row>
            ))}

            <ModalLayout
                open={deleteMode.open}
                width={'480px'}
                onClose={exitDeleteMode}
            >
                <BasicModal
                    head={'delete-role'}
                    desc={'delete-role-desc'}
                    _id={deleteMode.item?._id}
                    onClose={exitDeleteMode}
                    onSubmit={onDeleteRole}
                    loading={deletingRole}
                />
            </ModalLayout>
        </TableLayout>
    )
}


export default RolesTable
