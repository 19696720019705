import {useGetParams, useSetParams} from "../../../../services/react-query/setting/wages";
import {useEffect, useState} from "react";
import {deepCopy, stringToNumber} from "../../../../utils/common";


const useAffiliateSetting = () => {

    const { data: params, isFetching: fetchingParams } = useGetParams()
    const { mutate: updateParams, isLoading: updatingParams } = useSetParams()

    const [ detailsChanged, setDetailsChanged ] = useState(false)
    const [affiliatePc, setAffiliatePc] = useState(null)

    useEffect(() => {
        params && setAffiliatePc(params.affiliateSharePercent)
    }, [params])

    const checkChanges = () => {
        const changed = params?.affiliateSharePercent != affiliatePc
        setDetailsChanged(changed)
    }

    const onInputValueChange = (v) => {
        setAffiliatePc(v)
    }

    useEffect(() => {
        checkChanges()
    }, [affiliatePc])

    const onSubmitClicked = () => {
        const newParams = deepCopy(params)
        newParams.affiliateSharePercent = stringToNumber(affiliatePc)
        updateParams(newParams)
    }

    return {
        affiliatePc,
        onInputValueChange,
        loading: fetchingParams || updatingParams,
        detailsChanged,
        onSubmitClicked
    }
}


export default useAffiliateSetting
