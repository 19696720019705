import useGetTableIndex from "../../../../core/hooks/layout/useGetTableIndex";
import useCartable from "../../../../core/hooks/main/cartable/useCartable";
import RTableLayout from "../../layout/RTableLayout";
import {CMargin, DText, Flex, Row} from "../../../../styles/CommonStyles";
import {SOCKET_URL} from "../../../../core/constants/urls";
import Operation from "../../../common/utils/Operation";
import {balanceSignatureOperations} from "../../../../core/constants/operation";
import Text from "../../../../core/utils/Text";
import {TradeAmount, TradeTypeBadge} from "../../../../styles/main/orders";
import {formatNumber} from "../../../../core/utils/common";
import {MiniLineBreak, RespIndex} from "../../../../styles/main/MainCommonStyles";
import UserLink from "../../../common/utils/UserLink";
import {getTradeTypeColor} from "../../../../core/utils/theme";


const RCartableTable = ({ data }) => {

    const {data: multiSignatures} = data
    const { getTableIndex } = useGetTableIndex()

    const {
        hasWriteAccess,
        onOptionClicked,
        onDetailsClicked,
        onDeleteClicked,
        actionModal,
        closeActionModal,
        deleteModal,
        closeDeleteModal,
        onSubmitDelete,
        deleteLoading
    } = useCartable()

    return (
        <RTableLayout
            data={data}
        >
            {multiSignatures?.data?.map((item, idx) => (
                <Row
                    cs={'100%'}
                    index={idx}
                    key={item._id}
                >
                    <Flex justify={'space-between'}>
                        <Flex justify={'flex-start'}>
                            <img
                                src={SOCKET_URL + `assets/icon/${item.params.currency}.png`}
                                width={'32px'}
                                alt={' '}
                            />
                            <DText main style={{ margin: '0 8px' }}>
                                {item.params.currency?.toUpperCase()}
                            </DText>
                        </Flex>
                        <Flex justify={'flex-end'}>
                            <Operation
                                options={balanceSignatureOperations}
                                onOptionClicked={(idx) => onOptionClicked(idx, item)}
                                onDeleteClicked={() => onDeleteClicked(item)}
                                onDetailsClicked={() => onDetailsClicked(item)}
                                hasWriteAccess={item.status === 'pending' && hasWriteAccess}
                                hasDelete={item.status === 'pending' && !hasWriteAccess}
                                hasDetails
                            />
                        </Flex>
                    </Flex>

                    <CMargin margin={'8px'} />
                    <Flex justify='space-between'>
                        <DText main fontSize='s'>
                            <Text tid='askById' /> :
                        </DText>
                        <UserLink
                            _id={item.askBy}
                            name={item.askBy}
                        />
                    </Flex>
                    <MiniLineBreak />

                    <Flex justify={'space-between'}>
                        <DText fontSize={'s'} main>
                            <Text tid={'volume'} /> :
                        </DText>
                        <DText fontSize={'s'} primary>
                            {formatNumber(item.params.amount)}
                        </DText>
                    </Flex>
                    <MiniLineBreak />

                    <Flex justify={'space-between'}>
                        <DText fontSize={'s'} main>
                            <Text tid={'action'} /> :
                        </DText>
                        <TradeTypeBadge color={getTradeTypeColor(item.params.action.toLowerCase())}>
                            <Text tid={item.params.action.toLowerCase()} />
                        </TradeTypeBadge>
                    </Flex>
                    <MiniLineBreak />

                    <Flex justify={'space-between'}>
                        <DText fontSize={'s'} main>
                            <Text tid={'status'} /> :
                        </DText>
                        <TradeTypeBadge color={getTradeTypeColor(item.status)}>
                            <Text tid={item.status} />
                        </TradeTypeBadge>
                    </Flex>
                    <CMargin margin={'6px'} />
                    <RespIndex>
                        {idx + 1}
                    </RespIndex>
                </Row>
            ))}
        </RTableLayout>
    )
}

export default RCartableTable
