import { BiCheck } from 'react-icons/bi'
import { IoClose } from 'react-icons/io5'


const BooleanIcon = ({bool, size = 18}) => {

    return (
        <>
            {bool ?
                <BiCheck size={size} color={'#1ce087'} />
                :
                <IoClose size={size} color={'#e9106c'} />
            }
        </>
    )
}

export default BooleanIcon
