import styled, {keyframes} from "styled-components";
import { TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti'

export const NoAccessText = styled.div`
  font-size: 1.2rem;
  color: ${props => props.theme.primary};
  position: absolute;
  margin: auto;
  bottom: 40px;
  
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  };
`

const Animation = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
`

export const NoAccessDesc = styled.div`
  font-size: 0.9rem;
  color: ${props => props.theme.active};
  position: absolute;
  margin: auto;
  bottom: 20px;
  
  animation: ${Animation} 1s infinite;

  @media screen and (max-width: 768px) {
    font-size: 0.6rem;
  };
`


export const SortUp = styled(TiArrowSortedUp)`
  color: ${props => props.active ? props.theme.mainOrange : props.theme.color};
  margin-bottom: -3px;
`

export const SorDown = styled(TiArrowSortedDown)`
  color: ${props => props.active ? props.theme.mainOrange : props.theme.color};
  margin-top: -3px;
`


export const DetailCardWrapper = styled.div`
  width: ${props => props.width || '100%'};
  border: 1px solid ${props => props.theme.primary}20;
  border-radius: 4px;
  box-shadow: 0 0 4px rgb(0, 0, 0, 0.05);
  background-color: ${props => props.theme.detailBox};
  padding: 10px;
  margin: 15px 0;
  
  @media screen and (max-width: 768px) {
    width: 100%;
  };
`

export const DetailCardHeader = styled.div`
  padding: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
  border-bottom: 1px solid ${props => props.theme.color}20;
`
