import styled from "styled-components";
import {DText, Flex} from "../../../styles/CommonStyles";
import Avatar from "./Avatar";
import {Link} from "react-router-dom";
import {getUserTagColors} from "../../../core/utils/theme";
import {useMainContext} from "../../../core/contexts/main";


const UserTag = ({ user, idx = 0, width }) => {

    const { theme } = useMainContext()
    const path = `/users/${user?._id}`

    return (
        <Link to={path}>
            <UserTagWrapper width={width} color={getUserTagColors(idx, theme)}>
                <Flex fw justify={'flex-start'}>
                    <Avatar />
                    <DText color={'black'} lineBreak={'anywhere'} >
                        {user?.email}
                    </DText>
                </Flex>
            </UserTagWrapper>
        </Link>
    )
}


const UserTagWrapper = styled.div`
  padding: 4px 2px 4px 12px;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.secondary}20;
  background-color: ${props => props.color};
  cursor: pointer;
  width: ${props => props.width || 'max-content'};
  
  @media screen and (max-width: 1050px) {
    width: unset;
  }
`


export default UserTag
