import DetailCard from "../../../layout/main/details/DetailCard";
import {BalanceTag, CFlex, CMargin, DText, Flex} from "../../../../styles/CommonStyles";
import BooleanIcon from "../../../common/utils/BooleanIcon";
import UserTag from "../../../common/utils/UserTag";
import WorkspaceMembers from "./WorkspaceMembers";
import WorkspaceWithdraw from "./WorkspaceWithdraw";
import useWithdrawDetails from "../../../../core/hooks/main/reports/workspace/useWithdrawDetails";
import CoinRow from "../../../common/common/CoinRow";
import {formatNumber} from "../../../../core/utils/common";
import Tooltip from "../../../common/utils/Tooltip";
import DetailItem from "../../../layout/main/details/DetailItem";


const WorkspaceDetailView = ({ workspace }) => {

    const {
        creator,
        members,
        membersLoading
    } = useWithdrawDetails(workspace)


    return (
        <CFlex fw>
            {/* details */}
            <DetailCard
                header={'details'}
                date={workspace?.createdAt}
            >
                <Flex fw wrap justify={'space-between'}>
                    <DetailItem prefix={'creator'} suffix={<UserTag user={creator} />} />
                    <DetailItem prefix={'name'} suffix={workspace?.name} />
                    <DetailItem prefix={'user-code'} suffix={workspace?.code} />
                    <DetailItem prefix={'is-verified'} suffix={<BooleanIcon bool={workspace?.isVerified} />} />
                    <DetailItem prefix={'is-active'} suffix={<BooleanIcon bool={workspace?.isActive} />} />
                    {workspace?.balance?.map(balance => {
                        const total = balance.amount + balance.amountBlocked
                        return(
                            <BalanceTag>
                                <CoinRow coin={balance} />
                                <Tooltip
                                    content={`
                                        amount: ${formatNumber(balance.amount)} - blocked: ${formatNumber(balance.amountBlocked)} - total: ${formatNumber(total)}
                                    `}
                                >
                                    <DText primary style={{ cursor: 'pointer' }} margin={'0 8px'}>
                                        {`${formatNumber(balance.amount)}/${formatNumber(total)}`}
                                    </DText>
                                </Tooltip>
                            </BalanceTag>
                        )
                    })}
                </Flex>
                <CMargin margin={'8px'} />
                <Flex fw wrap justify={'space-between'}>

                </Flex>
            </DetailCard>
            {/* members */}
            <DetailCard
                header={'members'}
            >
                <WorkspaceMembers
                    members={members}
                    loading={membersLoading}
                />
            </DetailCard>

            {/* withdraw */}
            <DetailCard
                header={'withdraw-list'}
            >
                <WorkspaceWithdraw
                    workspace={workspace}
                    members={members}
                />
            </DetailCard>
        </CFlex>
    )
}


export default WorkspaceDetailView
