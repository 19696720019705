import {useNavigate} from "react-router-dom";
import RTableLayout from "../../../layout/RTableLayout";
import {CFlex, DText, Flex, Row} from "../../../../../styles/CommonStyles";
import Operation from "../../../../common/utils/Operation";
import Text from "../../../../../core/utils/Text";
import BooleanIcon from "../../../../common/utils/BooleanIcon";


const RContractTable = ({ data }) => {

    const navigate = useNavigate()
    const { data: contracts } = data
    const onDetailsClicked = (contracts) => navigate(`/reports/contracts/${contracts._id}`)

    return (
        <RTableLayout
            data={data}
        >
            {contracts?.data?.map((item, idx) => (
                <Row key={item._id} index={idx} cs={'50% 50%'}>
                    <div />
                    <Flex justify={'flex-end'}>
                        <Operation
                            onDetailsClicked={() => onDetailsClicked(item)}
                            hasDetails
                        />
                    </Flex>
                    <CFlex align={'flex-start'} justify={'space-around'} height={'120px'}>
                        <DText fontSize={'ss'} main>
                            <Text tid={'name'} /> :
                        </DText>
                        <DText fontSize={'ss'} main>
                            <Text tid={'is-active'} /> :
                        </DText>
                        <DText fontSize={'ss'} main>
                            <Text tid={'is-delete'} /> :
                        </DText>
                    </CFlex>
                    <CFlex align={'flex-start'} justify={'space-around'} height={'120px'}>
                        <DText fontSize={'ss'} primary>
                            {item.name}
                        </DText>
                        <BooleanIcon bool={item.isActive} />
                        <BooleanIcon bool={item.isDelete} />
                    </CFlex>
                </Row>
            ))}
        </RTableLayout>
    )
}

export default RContractTable
