import {useState} from "react";
import {useEffect} from "react";
import {normalFetch} from "../../../../core/services/fetch-api/get";
import {PaymentApproveListRow} from "../../../../styles/main/reports";
import {DText, Flex} from "../../../../styles/CommonStyles";
import Text from "../../../../core/utils/Text";
import {LineBreak} from "../../../../styles/main/MainCommonStyles";
import {ClipLoader} from "react-spinners";
import UserTag from "../../../common/utils/UserTag";
import {formatDate} from "../../../../core/utils/common";
import {useMainContext} from "../../../../core/contexts/main";
import BooleanIcon from "../../../common/utils/BooleanIcon";
import NoData from "../../../common/utils/NoData";


const PaymentApproveList = ({data}) => {

    const { lang } = useMainContext()
    const [items, setItems] = useState([])

    useEffect(() => {
        const fn = async () => {
            let newItems = []
            const members = Object.keys(data)
            for (const member of members) {
                const item = await normalFetch(`users/${member}`)
                newItems.push({
                    user: item?.data?.data,
                    date: data[member]
                })
            }
            setItems(newItems)
        }
        fn()

    }, [data])

    return (
        <>
            <PaymentApproveListRow>
                {headers.map(head => (
                    <DText main>
                        <Text tid={head} />
                    </DText>
                ))}
            </PaymentApproveListRow>
            {data?.length && <LineBreak />}
            {items.length ?
                items.map((item, idx) => (
                    <PaymentApproveListRow>
                        <UserTag user={item.user} idx={idx} />
                        {item.date ?
                            <DText primary>
                                {`${formatDate(item.date, 'date', lang)}-${formatDate(item.date, 'time', lang)}`}
                            </DText>
                            :
                            <BooleanIcon bool={false} />
                        }

                    </PaymentApproveListRow>
                ))
                :
                <Flex fw height={'200px'}>
                    {data?.length ?
                        <ClipLoader color={'#ffc800'}/>
                        :
                        <NoData/>
                    }
                </Flex>
            }
        </>
    )
}

const headers = [
    'member', 'approveDate'
]

export default PaymentApproveList
