import axios from "../../utils/axiosInstanse"
import {BASE_URL} from "../../constants/urls";


/**
 * Post api
 * @param {Object} data
 * @param {string} path
 * @param throwError
 * @returns {Promise}
 */
const postApi = (data, path, throwError) => {

    const resp = axios.post(
        BASE_URL + path, data
    )

    resp.catch(err => {
        if ((err?.response?.status >= 500 || !err?.response?.status) && throwError) {
            throwError(new Error(err))
        }
    })
    return resp
}

/**
 * Post api with token
 * @param {Object} data
 * @param {string} path
 * @returns {Promise}
 */
const postApiWithToken = (data, path) => {

    return axios.post(
        BASE_URL + path, data
    )
}


export {
    postApi,
    postApiWithToken
}

