import {useParams} from "react-router-dom";
import {useGetWorkspace} from "../../../core/services/react-query/report/workspace";
import {useState} from "react";
import DetailLayout from "../../../components/layout/main/details/DetailLayout";
import WorkspaceDetailView from "../../../components/main/reports/workspace/WorkspaceDetailView";
import ListLayout from "../../../components/layout/main/ListLayout";

const WorkspaceDetails = () => {

    const params = useParams()
    const [workspace, setWorkspace] = useState()


    return (
        <DetailLayout
            query={useGetWorkspace}
            onQuerySuccess={res => setWorkspace(res)}
            id={params.id}
        >
            <ListLayout>
                <WorkspaceDetailView
                    workspace={workspace}
                />
            </ListLayout>
        </DetailLayout>
    )
}

export default WorkspaceDetails
