import { useEffect, useState } from "react"
import { useCurrencies } from "../../core/services/react-query/setting"
import Text from "../../core/utils/Text"
import { CFlex, CMargin, DText } from "../../styles/CommonStyles"
import { AlertIcon, ModalHeader } from "../../styles/modals"
import ActionButton from "../common/buttons/ActionButton"
import Select from "../common/dropdown/Select"


const AddPairModal = ({
    currentPairs,
    loading,
    onSubmit
}) => {

    const { data: availableCoins } = useCurrencies()

    const [selectedPair, setSelectedPair] = useState(null)
    const [pairs, setPairs] = useState([])

    useEffect(() => {
        if (currentPairs && availableCoins) {
            const pairIds = currentPairs.map(item => item.id)
            const addablePairs = availableCoins.filter(item => !pairIds.includes(item.id))
            setPairs(addablePairs)
        }
    }, [currentPairs, availableCoins])


    const onSelectPair = (idx) => setSelectedPair(pairs[idx])


    return (
        <CFlex fw>
            <ModalHeader>
                <DText primary>
                    <Text tid={'add-pair'} />
                </DText>
            </ModalHeader>
            <CMargin margin='18px' />
            <Select
                options={pairs}
                value={selectedPair}
                onValueChange={onSelectPair}
                placeHolder={'select-pair'}
                width='100%'
                isCoin
                isPredefined
            />
            <CMargin margin={'18px'} />
            <ActionButton
                onClick={() => onSubmit(selectedPair)}
                active={!!selectedPair}
                loading={loading}
            >
                <Text tid={'submit'} />
            </ActionButton>
        </CFlex>
    )
}


export default AddPairModal
