import {useParams} from "react-router-dom";
import {useState} from "react";
import DetailLayout from "../../../components/layout/main/details/DetailLayout";
import ListLayout from "../../../components/layout/main/ListLayout";
import ContractDetailView from "../../../components/main/reports/contract/ContractDetailView";
import {useGetContract} from "../../../core/services/react-query/report/contract";


const ContractDetails = () => {

    const params = useParams()
    const [contract, setContract] = useState()


    return (
        <DetailLayout
            query={useGetContract}
            onQuerySuccess={res => setContract(res)}
            id={params.id}
        >
            <ListLayout>
                <ContractDetailView
                    contract={contract}
                />
            </ListLayout>
        </DetailLayout>
    )
}

export default ContractDetails
