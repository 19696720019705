


const CartableDetails = () => {


    return (
        <>
        </>
    )
}


export default CartableDetails
