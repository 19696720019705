import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import { MdDashboard, MdGeneratingTokens, MdOutlineFeed, MdSettingsInputComponent } from 'react-icons/md'
import { CgProfile, CgFeed, CgClipboard } from 'react-icons/cg'
import { FaWallet, FaRobot } from 'react-icons/fa'
import { HiUserGroup } from 'react-icons/hi'
import { TbAffiliate } from 'react-icons/tb'
import { BsFilesAlt } from 'react-icons/bs'
import { GiTwoCoins } from 'react-icons/gi'
import { BiCurrentLocation } from 'react-icons/bi'
import {SiGoogletagmanager, SiBlockchaindotcom, SiAdblock} from 'react-icons/si'
import { RiBankCard2Fill, RiListUnordered, RiCopperCoinFill, RiBankFill } from 'react-icons/ri'
import {useAclContext} from "../../contexts/acl";
import {useMainContext} from "../../contexts/main";
import {useWindowSize} from "../common/useWindowSize";
import {MOBILE_SIZE} from "../../constants/common";
import {IoMdAttach} from "react-icons/io";


const useSidebar = () => {

    const { width } = useWindowSize()
    const navigate = useNavigate()
    const { onSidebarChange } = useMainContext()
    const { permissions, loading } = useAclContext()

    const [routes, setRoutes] = useState(null)
    const [urlDirectAccess, setUrlDirectAccess] = useState(null)

    const onRouteClicked = (idx) => {
        if (routes[idx].items) {
            const newRoutes = [...routes]
            routes[idx].open = !routes[idx].open
            setRoutes(newRoutes)
        }else if (width < MOBILE_SIZE) {
            onSidebarChange()
        }
        navigate(routes[idx].path)
    }


    const onSubRouteClicked = (subrouteIndex, itemIndex) => {
        const items = routes[subrouteIndex]
        const route = items.items[itemIndex]
        navigate(route.path)
        if (width < MOBILE_SIZE) {
            onSidebarChange()
        }
    }

    useEffect(() => {
        if (!loading) {
            const { routes, urlAccess } = getSideRoutes(permissions)
            setRoutes(routes)
            setUrlDirectAccess(urlAccess)
        }
    }, [loading, permissions])


    return {
        urlDirectAccess, routes, onRouteClicked, onSubRouteClicked
    }
}



const getSideRoutes = (perms) => {


    /**
     * users access
     */
    const hasUserAccess = perms.user?.read

    /**
     * cartable access
     */
    const hasCartableAccess = perms.workspaceWithdraw?.read && perms.payment?.read

    const hasWalletAccess = perms.wallet?.read

    /**
     * report access
     */
    const hasWorkspaceAccess = perms.workspace?.read
    const hasContractAccess = perms.contract?.read
    const hasPaymentAccess = perms.payment?.read
    const hasAddressAccess = perms.address?.read
    const hasAffiliateAccess = perms.affiliate?.read
    const hasTraceAccess = perms.trace?.read
    const hasAllReportsAccess =
        hasWorkspaceAccess || hasContractAccess || hasPaymentAccess ||
        hasAddressAccess || hasAffiliateAccess || hasTraceAccess

    /**
     * setting access
     */
    const hasRolesAccess = perms.role?.read
    const hasSettingAccess = perms.settings?.read
    const hasAllSettingAccess = hasSettingAccess || hasRolesAccess

    const urlAccess = {
        '/login': true,
        '/profile': true,
        '/dashboard': true,
        '/users': hasUserAccess,
        '/cartable': hasCartableAccess,
        '/wallets': hasWalletAccess,
        '/reports': hasAllReportsAccess,
        '/setting': hasAllSettingAccess,
        '/reports/workspace': hasWorkspaceAccess,
        '/reports/contract': hasContractAccess,
        '/reports/payment': hasPaymentAccess,
        '/reports/addresses': hasAddressAccess,
        '/reports/blocked-users': true,
        '/reports/attachments': true,
        '/reports/affiliate': hasAffiliateAccess,
        '/reports/trace': hasTraceAccess,
        '/setting/roles': hasRolesAccess,
        '/setting/general': hasSettingAccess,
        '/setting/currencies': hasSettingAccess
    }

    const routes = [
        { title: 'profile', path: '/profile', icon: CgProfile, show: true },
        { title: 'dashboard', path: '/dashboard', icon: MdDashboard, show: true },
        { title: 'users', path: '/users', icon: HiUserGroup, show: hasUserAccess },
        { title: 'cartable', path: '/cartable', icon: CgClipboard, show: hasCartableAccess},
        { title: 'wallets', path: '/wallets', icon: RiBankFill, show: hasWalletAccess},
        {
            title: 'reports',
            items: [
                { title: 'workspace', path: '/reports/workspace', icon: RiListUnordered, show: hasWorkspaceAccess},
                { title: 'contract', path: '/reports/contract', icon: FaWallet, show: hasContractAccess},
                { title: 'payment', path: '/reports/payment', icon: RiBankCard2Fill, show: hasPaymentAccess},
                { title: 'addresses', path: '/reports/addresses', icon: BiCurrentLocation, show: hasAddressAccess },
                { title: 'affiliate', path: '/reports/affiliate', icon: TbAffiliate, show: hasAffiliateAccess },
                { title: 'trace', path: '/reports/trace', icon: CgFeed, show: hasTraceAccess},
                { title: 'blocked-users', path: '/reports/blocked-users', icon: SiAdblock, show: true},
                { title: 'attachments', path: '/reports/attachments', icon: IoMdAttach, show: true},
            ],
            open: false,
            show: hasAllReportsAccess,
            path: '/reports'
        },
        {
            title: 'setting',
            items: [
                { title: 'overall', path: '/setting/general', icon: MdSettingsInputComponent, show: hasSettingAccess },
                { title: 'roles', path: '/setting/roles', icon: SiGoogletagmanager, show: hasRolesAccess },
                { title: 'currencies', path: '/setting/currencies', icon: GiTwoCoins, show: hasRolesAccess },
            ],
            open: false,
            show: hasAllSettingAccess,
            path: '/setting'
        },
    ]

    return {
        urlAccess,
        routes
    }
}



export default useSidebar
