import {Link, useLocation, useNavigate} from "react-router-dom"
import { SOCKET_URL } from "../../../../../core/constants/urls"
import { formatNumber, getNames } from "../../../../../core/utils/common"
import Text from "../../../../../core/utils/Text"
import {CFlex, CMargin, DText, Flex, Row} from "../../../../../styles/CommonStyles"
import Operation from "../../../../common/utils/Operation"
import UserLink from "../../../../common/utils/UserLink"
import RTableLayout from '../../../layout/RTableLayout'
import {MiniLineBreak, RespIndex} from '../../../../../styles/main/MainCommonStyles'
import { getTradeTypeColor } from "../../../../../core/utils/theme"
import { TradeTypeBadge } from "../../../../../styles/main/orders"
import useGetTableIndex from "../../../../../core/hooks/layout/useGetTableIndex"
import CoinRow from "../../../../common/common/CoinRow";

const RTraceTable = ({
    data
}) => {

    const { data: traces } = data
    const location = useLocation()
    const navigate = useNavigate()

    const { getTableIndex } = useGetTableIndex()

    const onDetailsClicked = (trace) => {
        const path = location.pathname
        navigate(`${path}/${trace._id}`)
    }

    return (
        <RTableLayout
            data={data}
        >
            {traces?.data?.map((trace, idx) => {

                let pathname = '#'
                if (trace.entityType === 'workspace') pathname = `/reports/workspace/${trace.entityId}`
                if (trace.entityType === 'user') pathname = `/users/${trace.entityId}`

                return (
                    <Row cs={'50% 50%'} index={idx} key={trace._id}>
                        <CoinRow coin={{ currency: trace.currency }} />
                        <Flex justify={'flex-end'}>
                            <Operation
                                onDetailsClicked={() => onDetailsClicked(trace)}
                                hasDetails
                            />
                        </Flex>
                        <CFlex align={'flex-start'} justify={'space-around'} height={'150px'}>
                            <DText fontSize={'ss'} main>
                                <Text tid={'entityId'} /> :
                            </DText>
                            <DText fontSize={'ss'} main>
                                <Text tid={'entityType'} /> :
                            </DText>
                            <DText fontSize={'ss'} main>
                                <Text tid={'amount'} /> :
                            </DText>
                            <DText fontSize={'ss'} main>
                                <Text tid={'wage'} /> :
                            </DText>
                            <DText fontSize={'ss'} main>
                                <Text tid={'cause'} /> :
                            </DText>
                            <DText fontSize={'ss'} main>
                                <Text tid={'action'} /> :
                            </DText>
                        </CFlex>
                        <CFlex align={'flex-start'} justify={'space-around'} height={'150px'}>
                            <Link to={pathname}>
                                <DText primary>
                                    {trace.entityId}
                                </DText>
                            </Link>
                            <DText fontSize={'ss'} primary>
                                <Text tid={trace.entityType} />
                            </DText>
                            <DText fontSize={'ss'} primary>
                                {formatNumber(trace.amount)}
                            </DText>
                            <DText fontSize={'ss'} primary>
                                {formatNumber(trace.wage)}
                            </DText>
                            <TradeTypeBadge color={getTradeTypeColor(trace.cause)}>
                                <Text tid={trace.cause} />
                            </TradeTypeBadge>
                            <TradeTypeBadge color={getTradeTypeColor(trace.action)}>
                                <Text tid={trace.action} />
                            </TradeTypeBadge>
                        </CFlex>
                    </Row>
                )
            })}
        </RTableLayout>
    )
}


export default RTraceTable
