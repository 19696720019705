import ListLayout from "../../components/layout/main/ListLayout";
import {PageCard} from "../../styles/CommonStyles";
import WalletsTable from "../../components/main/wallets/WalletsTable";
import RWalletsTable from "../../components/responsive/main/wallets/RWalletsTable";
import {TABLET_SIZE} from "../../core/constants/common";
import {useState} from "react";
import {useWindowSize} from "../../core/hooks/common/useWindowSize";
import FilterLayout from "../../components/layout/filter/FilterLayout";
import {useGetWallets} from "../../core/services/react-query/wallets";
import {
    CacheKeys,
    walletFilterOptions, walletSortOptions
} from "../../core/constants/filter";


const Wallets = () => {

    const { width } = useWindowSize()

    const [ wallets, setWallets ] = useState({ data: null, loading: true })

    const onQuerySuccess = (res) => setWallets(res)

    const Component = width > TABLET_SIZE ? WalletsTable : RWalletsTable

    return (
        <ListLayout>
            <PageCard>
                <FilterLayout
                    query={useGetWallets}
                    cache={CacheKeys.WALLET}
                    onQuerySuccess={onQuerySuccess}
                    options={walletFilterOptions}
                    sortOptions={walletSortOptions}
                >
                    <Component data={wallets} />
                </FilterLayout>
            </PageCard>
        </ListLayout>
    )
}

export default Wallets
