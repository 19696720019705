import {Absolute, CFlex, CMargin, DeleteIcon, DText, Flex, IconWrapper} from "../../../../../styles/CommonStyles";
import Select from "../../../../common/dropdown/Select";
import {useCurrencies} from "../../../../../core/services/react-query/setting";
import {useGetPairs} from "../../../../../core/services/react-query/setting/pairs";
import {useEffect, useState} from "react";
import Text from "../../../../../core/utils/Text";
import Toggle from "../../../../common/buttons/Toggle";
import RobotOrderBoxUpdate from "../elements/RobotOrderBoxUpdate";
import {LineBreak} from "../../../../../styles/main/MainCommonStyles";
import {CollapseLtr, CollapseRtl, SettingCardWrapper} from "../../../../../styles/main/setting";
import {AnimatePresence, motion} from "framer-motion"
import {useMainContext} from "../../../../../core/contexts/main";
import {deepCopy} from "../../../../../core/utils/common";



const RobotMarketUpdate = (props) => {

    const { lang } = useMainContext()
    const {
        index,
        market,
        onDetailsChange,
        deleteMarket
    } = props

    const irtCoin = {
        id: 'irt', fa:  'تومان', name: 'Tooman'
    }

    const { data: availableCoins } = useCurrencies()
    const { data: _pairs } = useGetPairs()

    const [pairs, setPairs] = useState([])

    useEffect(() => {
        if (_pairs) {
            const newPairs = deepCopy(_pairs.data)
            newPairs.push(irtCoin)
            setPairs(newPairs)
        }
    }, [_pairs])

    const [selectedCoin, setSelectedCoin] = useState(null)
    const [selectedPair, setSelectedPair] = useState(null)
    const [showDetails, setShowDetails] = useState(false)

    useEffect(() => {
        if (availableCoins) {
            const coin = availableCoins.find(item => item.id === market.coin)
            if (coin) setSelectedCoin(coin)
        }
    }, [market, availableCoins])

    useEffect(() => {
        if (pairs) {
            let pair = pairs.find(item => item.id === market.pair)
            if (pair) setSelectedPair(pair)
        }
    }, [market, pairs])


    return (
        <SettingCardWrapper
            minHeight={'0'}
            main
        >
            <Flex fw justify={'space-between'} wrap align={'flex-end'}>
                <Flex style={{ minWidth: '300px' }} justify={'flex-start'} wrap>
                    <CFlex style={{ margin: '8px' }} align={'flex-start'}>
                        <DText main>
                            <Text tid={'coin'} />
                        </DText>
                        <CMargin margin={'4px'} />
                        <Select
                            options={availableCoins}
                            value={selectedCoin}
                            onValueChange={(idx) => onDetailsChange(availableCoins[idx].id, 'coin', index)}
                            placeHolder={'select-coin'}
                            width={'280px'}
                            height={'42px'}
                            isCoin
                        />
                    </CFlex>

                    <CFlex style={{ margin: '8px' }} align={'flex-start'}>
                        <DText main>
                            <Text tid={'pair'} />
                        </DText>
                        <CMargin margin={'4px'} />
                        <Select
                            options={pairs}
                            value={selectedPair}
                            onValueChange={(idx) => onDetailsChange(pairs[idx].id, 'pair', index)}
                            placeHolder={'select-pair'}
                            width={'280px'}
                            height={'42px'}
                            isCoin
                            isPredefined
                        />
                    </CFlex>
                </Flex>
            </Flex>
            <Absolute
                left={lang === 'fa' && '10px'}
                right={lang === 'en' && '10px'}
                top={'10px'}
                height={'unset'}
                width={'unset'}
            >
                <Flex>
                    <DText main>
                        <Text tid={'status'} /> :
                    </DText>
                    <Toggle
                        style={{ margin: '0 8px' }}
                        active={market.isActive}
                        onChange={() => onDetailsChange(!market.isActive, 'is-active', index)}
                    />
                    <IconWrapper onClick={() => deleteMarket(index)}>
                        <DeleteIcon size={24}/>
                    </IconWrapper>
                    {lang === 'en' ?
                        <CollapseLtr size={24} open={showDetails} onClick={() => setShowDetails(state => !state)}/>
                        :
                        <CollapseRtl size={24} open={showDetails} onClick={() => setShowDetails(state => !state)}/>
                    }
                </Flex>
            </Absolute>

            <LineBreak style={{ margin: '12px auto 0 auto' }}/>

            <AnimatePresence initial={false}>
                {showDetails &&
                    <motion.div
                        style={{ width: '100%', overflow: 'hidden' }}
                        variants={collapseVariants}
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                        <CFlex fw align={'flex-start'}>
                            <CMargin margin={'10px'} />
                            <DText primary>
                                <Text tid={'order-setting'} />
                            </DText>
                            <CMargin margin={'4px'} />

                            <Flex fw wrap justify={'space-around'}>
                                <RobotOrderBoxUpdate
                                    {...props}
                                    type={'order'}
                                    transaction={'buy'}
                                />
                                <RobotOrderBoxUpdate
                                    {...props}
                                    type={'order'}
                                    transaction={'sell'}
                                />
                            </Flex>

                            <CMargin margin={'4px'} />
                            <LineBreak />
                            <CMargin margin={'6px'} />

                            <DText primary>
                                <Text tid={'trade-setting'} />
                            </DText>
                            <CMargin margin={'4px'} />
                            <Flex fw wrap justify={'space-around'}>
                                <RobotOrderBoxUpdate
                                    {...props}
                                    type={'trade'}
                                    transaction={'buy'}
                                />
                                <RobotOrderBoxUpdate
                                    {...props}
                                    type={'trade'}
                                    transaction={'sell'}
                                />
                            </Flex>
                        </CFlex>
                    </motion.div>
                }
            </AnimatePresence>
        </SettingCardWrapper>
    )
}

const collapseVariants = {
    open: { opacity: 1, height: "auto" },
    collapsed: { opacity: 0, height: 0 }
}


export default RobotMarketUpdate
