import styled, {css} from "styled-components";
import Text from "../../../core/utils/Text";


const Tabbar = ({
    tabs,
    active,
    onTabClicked
}) => {


    return (
        <Wrapper>
            {tabs.map((tab, idx) => (
                <Tab
                    key={tab}
                    active={active === idx + 1}
                    onClick={() => onTabClicked(idx + 1)}
                >
                    <Text tid={tab} />
                </Tab>
            ))}
        </Wrapper>
    )
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 1px solid ${props => props.theme.color}15;
  align-items: flex-end;
  overflow-x: auto;

  &::-webkit-scrollbar{
    display: none;
  };
  -ms-overflow-style: none;
  scrollbar-width: none;
`

const Tab = styled.div`
  color: ${props => props.theme.primary};
  font-size: 0.9rem;
  transition: all 0.3s;
  margin: 0 15px;
  cursor: pointer;
  padding: 10px;
  width: fit-content;
  white-space: nowrap;
  
  ${props => props.active && css`
    border-bottom: 1px solid ${props => props.theme.active};
  `};
  
  &:hover{
    ${props => !props.active && css`
      box-shadow: 0 0 4px rgb(0, 0, 0, 0.15);
      border-bottom: 1px solid ${props => props.theme.active}80;
      border-radius: 12px;
    `};
  };
  
  @media screen and (max-width: 1050px) {
    font-size: 0.8rem;
  };

  @media screen and (max-width: 768px) {
    font-size: 0.7rem;
    margin: 0 5px;
    padding: 10px 6px 10px 6px;
  };
`

export default Tabbar
