import MotionFade from "../../../common/utils/MotionFade";
import {CFlex, CMargin, DText, Flex} from "../../../../styles/CommonStyles";
import Text from "../../../../core/utils/Text";
import {HorizontalLineBreak, LineBreak} from "../../../../styles/main/MainCommonStyles";
import BooleanIcon from "../../../common/utils/BooleanIcon";


const SwapBox = ({ detail, currency }) => {


    const Item = ({ prefix, suffix }) => {

        return (
            <Flex style={{ margin: '4px 0' }} fw justify={'space-between'}>
                <DText main>
                    <Text tid={prefix} />:
                </DText>
                <DText primary style={{ direction: 'ltr' }} fontSize={!prefix.includes('actor') && 's'}>
                    {prefix === 'isActive' ?
                        <BooleanIcon bool={suffix} />
                        :
                        (
                            `${suffix} ${prefix.includes('actor') ? '%' : `(${currency?.toUpperCase()})`}`
                        )
                    }
                </DText>
            </Flex>
        )
    }

    return (
        <MotionFade>
            {detail &&
                <Flex fw justify={'space-between'}>
                    <CFlex width={'49%'} align={'flex-start'}>
                        <CMargin margin={'3px'} />
                        <DText main fontSize={'s'}>
                            <Text tid={'sender'} />
                        </DText>
                        <LineBreak />
                        {Object.keys(detail?.sender)?.map(key => (
                            <Item
                                prefix={key}
                                suffix={detail?.sender[key]}
                            />
                        ))}
                    </CFlex>
                    <HorizontalLineBreak style={{ height: '180px' }}/>
                    <CFlex width={'49%'} align={'flex-start'}>
                        <CMargin margin={'3px'} />
                        <DText main fontSize={'s'}>
                            <Text tid={'receiver'}/>
                        </DText>
                        <LineBreak />
                        {Object.keys(detail?.receiver)?.map(key => (
                            <Item
                                prefix={key}
                                suffix={detail?.sender[key]}
                            />
                        ))}
                    </CFlex>
                </Flex>
            }
        </MotionFade>
    )
}


export default SwapBox
