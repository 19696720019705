import {Link} from "react-router-dom"
import {Column, DText, Row} from "../../../../../styles/CommonStyles"
import TableLayout from "../../../../layout/main/TableLayout"
import useGetTableIndex from "../../../../../core/hooks/layout/useGetTableIndex";
import {traceAttributes} from "../../../../../core/constants/headers";
import Text from "../../../../../core/utils/Text";
import CoinRow from "../../../../common/common/CoinRow";
import {formatNumber} from "../../../../../core/utils/common";
import {TradeTypeBadge} from "../../../../../styles/main/orders";
import {getTradeTypeColor} from "../../../../../core/utils/theme";
import DateTime from "../../../../common/utils/DateTime";



const TraceTable = ({data}) => {

    const { data: traces } = data
    const { cs, headers } = traceAttributes

    const { getTableIndex } = useGetTableIndex()

    return (
        <TableLayout
            headers={headers}
            cs={cs}
            data={data}
            hasWriteAccess
        >
            {traces?.data?.map((trace, idx) => {

                let pathname = '#'
                if (trace.entityType === 'workspace') pathname = `/reports/workspace/${trace.entityId}`
                if (trace.entityType === 'user') pathname = `/users/${trace.entityId}`

                return (
                    <Row cs={cs} index={idx} key={trace._id}>
                        <Column>
                            {getTableIndex(idx)}
                        </Column>
                        <Column avatar>
                            <Link to={pathname}>
                                <DText primary>
                                    {trace.entityId}
                                </DText>
                            </Link>
                        </Column>
                        <Column>
                            <Text tid={trace.entityType} />
                        </Column>
                        <Column>
                            <CoinRow coin={{ currency: trace.currency }} />
                        </Column>
                        <Column>
                            {formatNumber(trace.amount)}
                        </Column>
                        <Column>
                            {formatNumber(trace.wage)}
                        </Column>
                        <Column center>
                            <TradeTypeBadge color={getTradeTypeColor(trace.cause)}>
                                <Text tid={trace.cause} />
                            </TradeTypeBadge>
                        </Column>
                        <Column center>
                            <TradeTypeBadge color={getTradeTypeColor(trace.action)}>
                                <Text tid={trace.action} />
                            </TradeTypeBadge>
                        </Column>
                        <Column center>
                            <DateTime dt={trace.createdAt} />
                        </Column>
                    </Row>
                )
            })}
        </TableLayout>
    )

}



export default TraceTable
