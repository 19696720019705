

const RBlockedUsersTable = () => {

    return (
        <>
        </>
    )
}

export default RBlockedUsersTable
