import TableLayout from "../../../layout/main/TableLayout";
import useGetTableIndex from "../../../../core/hooks/layout/useGetTableIndex";
import {Column, DText, Flex, Row} from "../../../../styles/CommonStyles";
import UserLink from "../../../common/utils/UserLink";
import {SOCKET_URL} from "../../../../core/constants/urls";
import DateTime from "../../../common/utils/DateTime";
import {addressAttributes} from "../../../../core/constants/headers";
import NameTag from "../../../common/utils/NameTag";
import CoinRow from "../../../common/common/CoinRow";


const AddressesTable = ({
    data
}) => {

    const { getTableIndex } = useGetTableIndex()
    const { data: addresses } = data
    const { cs, headers } = addressAttributes

    return (
        <TableLayout
            data={data}
            headers={headers}
            cs={cs}
        >
            {addresses?.data?.map((address, idx) => {



                return (
                    <Row index={idx} key={address._id} cs={cs}>
                        <Column>
                            {getTableIndex(idx)}
                        </Column>
                        <Column>
                            <NameTag name={address.name} />
                        </Column>
                        <Column>
                            <UserLink _id={address.user?._id} name={address.user?.email} />
                        </Column>
                        <Column>
                            <CoinRow coin={{ currency: address.currency }} />
                        </Column>
                        <Column style={{lineBreak: 'anywhere'}}>
                            {address.address}
                        </Column>
                        <Column center>
                            <DateTime dt={address.createdAt} />
                        </Column>
                    </Row>
                )
            })}
        </TableLayout>
    )
}

export default AddressesTable
