import {useQuery} from "react-query";
import {filterFetch, normalFetch} from "../../../fetch-api/get";
import {useMainContext} from "../../../../contexts/main";
import {makeQueryFilters} from "../../../../utils/query-maker";


export const WORKSPACE_KEYS = {
    LIST_WORKSPACES: 'list-workspaces',
    GET_WORKSPACE: 'get-workspace',
    WITHDRAW_LIST: 'withdraw-list'
}


const useListWorkspaces = (filters, pagination, extra, csv) => {

    const { lang } = useMainContext()
    const params = {...makeQueryFilters(filters, lang), ...pagination}
    if (csv){
        params.export = 'csv'
    }

    return useQuery(
        WORKSPACE_KEYS.LIST_WORKSPACES, () => filterFetch(params, 'workspaces/admin')
    )
}


const useGetWorkspace = (id, onSuccess) => {

    return useQuery(
        [WORKSPACE_KEYS.GET_WORKSPACE, id], () => normalFetch(`workspaces/admin/${id}`),
        {
            select: res => res?.data?.data,
            enabled: false,
            onSuccess: (res) => {
                onSuccess(res)
            }
        }
    )
}

const useWorkspaceWithdrawList = (filters, pagination, extra, csv) => {

    const { lang } = useMainContext()
    const newFilters = { ...extra, ...filters }
    const params = {...makeQueryFilters(newFilters, lang), ...pagination}
    if (csv){
        params.export = 'csv'
    }

    return useQuery(
        WORKSPACE_KEYS.WITHDRAW_LIST, () => filterFetch(params, 'workspaces/withdraws/admin')
    )
}


export {
    useListWorkspaces,
    useGetWorkspace,
    useWorkspaceWithdrawList
}
