import {ProfileAvatar} from "../../../styles/main/profile";
import styled from "styled-components";
import { FaUser } from 'react-icons/fa'
import {HiUserGroup} from "react-icons/hi";


const Avatar = ({
    type = 'user'
}) => {

    return (
        <ProfileAvatar style={{ margin: '0 5px' }}>
            { type === 'user' ?
                <Icon size={14} />
                :
                <HiUserGroup color={'black'} size={18} />
            }
        </ProfileAvatar>
    )
}


const Icon = styled(FaUser)`
  color: #161a1e;
`

export default Avatar
