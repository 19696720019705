import {useEffect, useState} from "react";
import {useCreateARole, useGetRoleConfig, useUpdateARole} from "../../../../services/react-query/setting/role";
import {deepCopy} from "../../../../utils/common";


const useRoleSetting = () => {

    const [role, setRole] = useState(null)
    const [roles, setRoles] = useState({data: null, loading: true})

    const onQuerySuccess = (res) => setRoles(res)

    const { data: roleConfig } = useGetRoleConfig()
    const { mutate: createRole, isLoading: creatingRole, isSuccess: roleCreated } = useCreateARole()
    const { mutate: updateRole, isLoading: updatingRole, isSuccess: roleUpdated } = useUpdateARole()

    const [editMode, setEditMode] = useState(false)

    const enterEditMode = (_role) => {
        if (!!_role) {
            if (roleConfig) {
                const completeRole = deepCopy(_role)
                const permissions = completeRole.permissions
                const configPermissions = roleConfig.permissions
                if (permissions.length !== configPermissions.length) {
                    for (let i = permissions.length; i < configPermissions.length; i++) {
                        permissions[i] = configPermissions[i]
                    }
                }
                setRole(completeRole)
            }else {
                setRole(_role)
            }
        }
        !_role && setRole(roleConfig)
        setEditMode(true)
    }

    const exitEditMode = () => {
        setEditMode(false)
    }

    const onPermissionChange = (type, index, status) => {
        let _status;
        const newRole = deepCopy(role)
        const permissions = newRole.permissions
        switch (type) {
            case 'role-name':
                newRole.name = index
                break
            case 'read':
                permissions[index].activity.read = !permissions[index].activity.read
                break
            case 'write':
                permissions[index].activity.write = !permissions[index].activity.write
                break
            case 'read-write':
                const active = permissions[index].activity.read || permissions[index].activity.write
                permissions[index].activity.read = !active
                permissions[index].activity.write = !active
                break
            case 'read-access':
                // all read
                _status = status.readAll
                permissions.forEach(perm => perm.activity.read = !_status)
                break
            case 'write-access':
                // all write
                _status = status.writeAll
                permissions.forEach(perm => perm.activity.write = !_status)
                break
            case 'read-write-access':
                // all read and write
                _status = status.readAll && status.writeAll
                permissions.forEach(perm => {
                    perm.activity.read = !_status
                    perm.activity.write = !_status
                })
                break
            default:
                break

        }
        setRole(newRole)
    }

    const onSubmitChanges = () => {
        if (creatingRole || updatingRole) return
        const payload = deepCopy(role)
        for (const perm of payload.permissions) {
            delete perm.description
        }
        if (role?._id) {
            const newPayload = {permissions: [...payload.permissions], name: payload.name}
            updateRole({data: newPayload, id: role?._id})
        }else {
            createRole(payload)
        }
    }

    useEffect(() => {
        if (roleUpdated || roleCreated) {
            exitEditMode()
        }
    }, [roleUpdated, roleCreated])


    return {
        editMode,
        enterEditMode,
        exitEditMode,
        roles,
        role,
        onPermissionChange,
        onSubmitChanges,
        onQuerySuccess,
        loading: creatingRole || updatingRole
    }
}

export default useRoleSetting
