import TableLayout from "../../../../layout/main/TableLayout"
import {Column, Flex, Row} from '../../../../../styles/CommonStyles'
import {getNames} from "../../../../../core/utils/common";
import Avatar from "../../../../common/utils/Avatar";
import UserLink from "../../../../common/utils/UserLink";
import DateTime from "../../../../common/utils/DateTime";
import ShareBar from "../ShareBar";
import useAffiliateList from "../../../../../core/hooks/main/affiliates/useAffiliatesList";
import Operation from "../../../../common/utils/Operation";
import ModalLayout from "../../../../layout/main/ModalLayout";
import BasicModal from "../../../../modals/BasicModal";
import useGetTableIndex from "../../../../../core/hooks/layout/useGetTableIndex";
import {affiliateListAttribute} from "../../../../../core/constants/headers";


const AffiliateListTable = ({
    data
}) => {

    const {data: affiliates} = data
    const { cs, headers } = affiliateListAttribute
    const { getTableIndex } = useGetTableIndex()

    const {
        hasWriteAccess,
        loading,
        onDeleteClicked,
        onDetailsClicked,
        onSubmitClicked,
        modal,
        onModalClose
    } = useAffiliateList()

    return (
        <TableLayout
            headers={headers}
            cs={cs}
            data={data}
            hasWriteAccess={hasWriteAccess}
        >
            {affiliates?.data?.map((item, idx) => {

                const {
                    FULLNAME, NAMEAVATAR
                } = getNames(
                    item.user?.firstName,
                    item.user?.lastName
                )

                return (
                    <Row cs={cs} index={idx} key={item._id}>
                        <Column>
                            {getTableIndex(idx)}
                        </Column>
                        <Column avatar>
                            <Flex width='42px'>
                                <Avatar
                                    name={NAMEAVATAR}
                                    avatar={item.user?.avatar}
                                />
                            </Flex>
                            <UserLink
                                _id={item.userId}
                                name={FULLNAME}
                            />
                        </Column>

                        <Column>
                            {item.code}
                        </Column>

                        <Column>
                            {item.used}
                        </Column>

                        <Column>
                            {item.maxUse}
                        </Column>

                        <Column center>
                            <ShareBar
                                affiliate={item}
                            />
                        </Column>

                        <Column center>
                            <DateTime dt={item.createdAt} />
                        </Column>
                        <Column>
                            <Operation
                                onDeleteClicked={() => onDeleteClicked(item)}
                                onDetailsClicked={() => onDetailsClicked(item)}
                                deleteTooltip={'delete-affiliate'}
                                hasWriteAccess={hasWriteAccess}
                                hasDetails
                                hasDelete
                            />
                        </Column>
                    </Row>
                )
            })}
            <ModalLayout
                open={modal.open}
                width={'520px'}
                onClose={onModalClose}
            >
                <BasicModal
                    head={'delete-affiliate-head'}
                    desc={'delete-affiliate-desc'}
                    _id={modal.affiliate?._id}
                    onClose={onModalClose}
                    onSubmit={onSubmitClicked}
                    loading={loading}
                />
            </ModalLayout>
        </TableLayout>
    )
}

export default AffiliateListTable
