import { useEffect } from "react"
import Skeleton from "../../../../core/packages/skeleton";
import SettingCardSkeleton from "../../../skeleton/SettingCardSkeleton";
import {CFlex, Flex} from "../../../../styles/CommonStyles";
import ListLayout from "../ListLayout";



const DetailLayout = ({
    query,
    onQuerySuccess,
    id,
    children
}) => {

    const { refetch, isFetching: loading} = query(id, onQuerySuccess)

    useEffect(() => {
        if (id) refetch()
    }, [id])

    return (
        <>
            {loading ?
                <ListLayout>
                    <CFlex fw>
                        <Flex fw justify={'space-around'} wrap>
                            <Skeleton
                                Content={SettingCardSkeleton}
                            />
                            <Skeleton
                                Content={SettingCardSkeleton}
                            />
                        </Flex>
                        <Flex fw justify={'space-around'} wrap>
                            <Skeleton
                                Content={SettingCardSkeleton}
                            />
                            <Skeleton
                                Content={SettingCardSkeleton}
                            />
                        </Flex>
                    </CFlex>
                </ListLayout>
                :
                children
            }
        </>
    )

}


export default DetailLayout
