import {CFlex, CMargin, ColorBox, DText, Flex} from "../../../../../styles/CommonStyles"
import MotionFade from "../../../../common/utils/MotionFade"
import Text from "../../../../../core/utils/Text";
import {SOCKET_URL} from "../../../../../core/constants/urls";


const DetailBox = ({
    coin
}) => {

    const Item = ({ prefix, suffix }) => {
        return (
            <Flex fw justify={'space-between'}>
                <DText main>
                    <Text tid={prefix} />:
                </DText>
                <DText primary margin={'0 5px'}>
                    {suffix}
                </DText>
            </Flex>
        )
    }

    return (
        <MotionFade>
            <Flex justify='space-around' fw wrap>
                <CFlex width={'300px'} justify={'space-between'} align={'space-between'}>
                    <CMargin margin={'8px'} />
                    <Item
                        prefix={'coin'}
                        suffix={
                            <Flex style={{ direction: 'ltr' }}>
                                <img
                                    src={SOCKET_URL + `icons/${coin?.currency}.png`}
                                    width='28px'
                                    alt={' '}
                                    style={{ marginRight: '4px' }}
                                />
                                <DText primary>
                                    {`${coin?.name}/${coin?.currency}`}
                                </DText>
                            </Flex>
                        }
                    />
                    <CMargin margin={'6px'} />
                    <Item
                        prefix={'type'}
                        suffix={
                            <Text tid={coin.type} />
                        }
                    />
                </CFlex>
                <CFlex width={'300px'} justify={'space-between'} align={'space-between'}>
                    <CMargin margin={'6px'} />
                    <Item
                        prefix={'decimal'}
                        suffix={coin?.decimal}
                    />
                    <CMargin margin={'4px'} />
                    <Item
                        prefix={'color'}
                        suffix={
                            <ColorBox color={coin?.color} />
                        }
                    />
                </CFlex>
            </Flex>
        </MotionFade>
    )
}


export default DetailBox
