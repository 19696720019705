import {useGetAUser} from "../../../../services/react-query/user";
import {useEffect, useState} from "react";
import {normalFetch} from "../../../../services/fetch-api/get";


const useWithdrawDetails = (workspace) => {

    const { data: creator, refetch: getCreator } = useGetAUser(workspace?.createdBy)
    useEffect(() => {
        if (workspace) getCreator()
    }, [workspace])

    const [members, setMembers] = useState([])
    const [membersLoading, setMembersLoading] = useState(true)

    useEffect(() => {
        const formatData = async () => {
            if (workspace?.members) {
                const mems = []
                for (const mem of workspace?.members){
                    const temp = {...mem}
                    let user = await normalFetch(`users/${temp.userId}`)
                    temp.user = user?.data?.data
                    mems.push(temp)
                }
                setMembersLoading(false)
                setMembers(mems)
            }
        }

        formatData()

    }, [workspace])

    return {
        creator,
        members,
        membersLoading
    }

}


export default useWithdrawDetails
