import { formatNumber } from "../../../../core/utils/common"
import Text from "../../../../core/utils/Text"
import { CMargin, Decoration, DText, Flex } from "../../../../styles/CommonStyles"
import MotionFade from "../../../common/utils/MotionFade"
import SettingRow from "../../../common/common/SettingRow"
import BoundBox from "../available-coins/details/BoundBox"


const TransferBox = ({
    detail,
    currency
}) => {

    return (
        <MotionFade>
            {detail &&
                <>
                    <Flex fw justify='flex-start'>
                        <Decoration />
                        <DText main>
                            <Text tid='available-order-min-max-note' />
                        </DText>
                    </Flex>
                    <BoundBox
                        min={detail.min}
                        max={detail.max}
                        currency={currency}
                    />
                    <CMargin margin='8px' />
                    <Flex fw justify='flex-start'>
                        <Decoration />
                        <DText main>
                            <Text tid='available-order-note' />
                        </DText>
                    </Flex>
                    <CMargin margin='8px' />
                    <SettingRow
                        prefix='receiver-fee-factor'
                        suffix={`${detail?.receiver?.feeFactor} %`}
                    />
                    <SettingRow
                        prefix={'receiver-fee-min'}
                        suffix={formatNumber(detail?.receiver?.feeMin)}
                        coin={currency}
                        priceNumber
                    />
                    <SettingRow
                        prefix={'receiver-fee-max'}
                        suffix={formatNumber(detail?.receiver?.feeMax)}
                        coin={currency}
                        priceNumber
                    />
                    <SettingRow
                        prefix='sender-fee-factor'
                        suffix={`${detail?.sender?.feeFactor} %`}
                    />
                    <SettingRow
                        prefix={'sender-fee-min'}
                        suffix={formatNumber(detail?.sender?.feeMin)}
                        coin={currency}
                        priceNumber
                    />
                    <SettingRow
                        prefix={'sender-fee-max'}
                        suffix={formatNumber(detail?.sender?.feeMax)}
                        coin={currency}
                        priceNumber
                    />
                    <SettingRow
                        prefix={'is-active'}
                        suffix={detail?.isActive}
                        isRadio
                    />
                </>
            }

        </MotionFade>
    )
}


export default TransferBox
