import {useProfileQuery} from "../../core/services/react-query/profile";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";


const Profile = () => {

    const navigate = useNavigate()
    const { data: profile } = useProfileQuery()
    useEffect(() => {
        if (profile) navigate(`/profile/${profile._id}`, {state: {profile: true}})
    }, [profile])

    return (
        <></>
    )
}



export default Profile
