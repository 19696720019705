import TableLayout from "../../../layout/main/TableLayout";
import {attachmentAttributes} from "../../../../core/constants/headers";
import useGetTableIndex from "../../../../core/hooks/layout/useGetTableIndex";
import {Column, Row} from "../../../../styles/CommonStyles";
import UserLink from "../../../common/utils/UserLink";
import DateTime from "../../../common/utils/DateTime";


const AttachmentsTable = ( { data } ) => {

    const { headers, cs } = attachmentAttributes
    const { getTableIndex } = useGetTableIndex()

    return (
        <TableLayout
            data={data}
            headers={headers}
            cs={cs}
        >
            {data?.data?.data?.map((item, idx) => {

                return (
                    <Row key={item._id} cs={cs}>
                        <Column>
                            {getTableIndex(idx)}
                        </Column>
                        <Column>
                            {item.path}
                        </Column>
                        <Column>
                            {item.size}
                        </Column>
                        <Column>
                            <UserLink _id={item.userId} name={item.userId}  />
                        </Column>
                        <Column center>
                            <DateTime dt={item.createdAt} />
                        </Column>
                    </Row>
                )
            })}
        </TableLayout>
    )
}

export default AttachmentsTable
