import ListLayout from "../../../components/layout/main/ListLayout";
import {PageCard} from "../../../styles/CommonStyles";
import Tabbar from "../../../components/common/tabs/Tabbar";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import WagesTrace from "../../../components/main/reports/trace/tabs/WagesTrace";
import Trace from "../../../components/main/reports/trace/tabs/Trace";


const TraceMain = () => {

    const search = new URLSearchParams(useLocation().search)
    let tab
    let currency
    try {
        tab = parseInt(search.get('tab'))
        currency = search.get('currency')
    }catch (_) {}

    const tabs = [ 'wages', 'traces']
    const [activeTab, setActiveTab] = useState(
        tab ? tab : 1
    )

    useEffect(() => {
        if (tab) setActiveTab(tab)
    }, [tab])

    const navigate = useNavigate()
    const onTabClicked = (idx) => {
        const options = {
            search: `?tab=${idx}`
        }
        navigate(options, { replace: true })
        setActiveTab(idx)
    }

    const comps = [ WagesTrace, Trace ]
    const ActiveComp = comps[activeTab-1]

    return (
        <ListLayout>
            <PageCard>
                <Tabbar
                    tabs={tabs}
                    active={activeTab}
                    onTabClicked={onTabClicked}
                />
                <ActiveComp currency={currency}/>
            </PageCard>
        </ListLayout>
    )
}


export default TraceMain
