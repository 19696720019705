import {SketchPicker} from "react-color";
import {useRef, useState} from "react";
import {Absolute, ColorBox, Relative} from "../../../styles/CommonStyles";
import useClickOutside from "../../../core/hooks/common/useClickOutside";
import {CloseIcon} from "../../../styles/components/common/select-styles";


const ColorPicker = ({ color, onChange }) => {

    const ref = useRef()

    const [open, setOpen] = useState(false)
    const [_color, setColor] = useState(color)

    const onClose = () => {
        if (open) {
            setOpen(false)
            onChange(_color)
        }
    }

    useClickOutside(ref, onClose)

    return (
        <Relative ref={ref}>
            <ColorBox
                color={_color}
                onClick={() => setOpen(true)}
            />
            <div style={{ position: 'absolute', zIndex: 1001 }}>
                {open &&
                    <SketchPicker
                        color={_color}
                        onChange={(color) => {
                            setColor(color.hex.replace('#', ''));
                        }}
                    />
                }
            </div>
        </Relative>
    )
}


export default ColorPicker
