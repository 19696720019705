import ListLayout from "../../../components/layout/main/ListLayout";
import {PageCard} from "../../../styles/CommonStyles";
import {useState} from "react";
import Tabbar from "../../../components/common/tabs/Tabbar";
import {useWindowSize} from "../../../core/hooks/common/useWindowSize";
import {MOBILE_SIZE} from "../../../core/constants/common";
import AffiliateSetting from "../../../components/main/setting/general/affiliate/AffiliateSetting";


const TABS = ['affiliate']
const RESP_TABS = [...TABS, 'overall']

const General = () => {

    const { width } = useWindowSize()

    const [activeTab, setActiveTab] = useState(1)
    const onTabChange = (idx) => {
        setActiveTab(idx)
    }

    const comps = [
        AffiliateSetting
    ]
    const ActiveComp = comps[activeTab - 1]

    return (
        <ListLayout>
            <PageCard>
                <Tabbar
                    tabs={width > MOBILE_SIZE ? TABS : RESP_TABS}
                    active={activeTab}
                    onTabClicked={onTabChange}
                />
                <ActiveComp />
            </PageCard>
        </ListLayout>
    )
}

export default General;
