import {useEffect, useState} from "react";
import {Column, Row} from "../../../../styles/CommonStyles";
import {BalanceWrapper} from "../../../../styles/main/user";
import Text from "../../../../core/utils/Text";
import CoinRow from "../../../common/common/CoinRow";
import {formatNumber} from "../../../../core/utils/common";
import NoData from "../../../common/utils/NoData";

const UserBalances = ({
    user
}) => {

    return (
        <BalanceWrapper fw>
            <Row cs={CS} header>
                {headers.map(head => (
                    <Column>
                        <Text tid={head} />
                    </Column>
                ))}
            </Row>

            { user?.balance?.length ?
                user.balance.map((balance, idx) => (
                    <Row index={idx} cs={CS}>
                        <Column>
                            {idx + 1}
                        </Column>
                        <Column>
                            <CoinRow
                                coin={balance}
                            />
                        </Column>
                        <Column>
                            {formatNumber(balance.amount)}
                        </Column>
                        <Column>
                            {formatNumber(balance.amountBlocked)}
                        </Column>
                    </Row>
                ))
                :
                <NoData />
            }
        </BalanceWrapper>
    )
}

const CS = '10% 30% 30% 30%'
const headers = [
    'index', 'currency', 'amount', 'amount-blocked'
]

export default UserBalances
