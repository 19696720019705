
const TokenDetails = () => {



    return (
        <>
        </>
    )
}


export default TokenDetails
