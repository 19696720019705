import MotionFade from "../../../common/utils/MotionFade";
import {Background, CFlex, CMargin, Decoration, DText, Flex} from "../../../../styles/CommonStyles";
import Input from "../../../common/input/Input";
import Text from "../../../../core/utils/Text";
import CheckBox from "../../../common/input/CheckBox";


const SwapUpdate = ({
    detail,
    type,
    onDetailChange,
    currency
}) => {


    return (
        <MotionFade>
            {detail &&
                <CFlex fw align='flex-start'>
                    <CMargin margin='4px' />
                    <Flex fw wrap justify='space-between'>
                        <Background bg='detailBox'>
                            <Input
                                value={detail.sender.min}
                                label={'sender-min'}
                                onInputChange={(v) => onDetailChange({
                                    type,
                                    value: v,
                                    key: 'sender.min'
                                })}
                                minWidth='260px'
                                width='49%'
                                currency={currency}
                                number
                            />
                            <Input
                                value={detail.receiver.min}
                                label={'receiver-min'}
                                onInputChange={(v) => onDetailChange({
                                    type,
                                    value: v,
                                    key: 'receiver.min'
                                })}
                                minWidth='260px'
                                width='49%'
                                currency={currency}
                                number
                            />
                        </Background>
                    </Flex>

                    <Flex fw wrap justify='space-between'>
                        <Background bg='detailBox'>
                            <Input
                                value={detail?.sender?.max}
                                label={'sender-max'}
                                onInputChange={(v) => onDetailChange({
                                    type,
                                    value: v,
                                    key: 'sender.max'
                                })}
                                minWidth='260px'
                                width='49%'
                                currency={currency}
                                number
                            />
                            <Input
                                value={detail?.receiver?.max}
                                label={'receiver-max'}
                                onInputChange={(v) => onDetailChange({
                                    type,
                                    value: v,
                                    key: 'receiver.max'
                                })}
                                minWidth='260px'
                                width='49%'
                                currency={currency}
                                number
                            />
                        </Background>
                    </Flex>

                    <Flex fw wrap justify='space-between'>
                        <Background bg='detailBox'>
                            <Input
                                value={detail?.sender?.feeFactor}
                                label={'sender-fee-factor'}
                                onInputChange={(v) => onDetailChange({
                                    type,
                                    value: v,
                                    key: 'sender.feeFactor'
                                })}
                                minWidth='260px'
                                width='49%'
                                code
                            />
                            <Input
                                value={detail?.receiver?.feeFactor}
                                label={'receiver-fee-factor'}
                                onInputChange={(v) => onDetailChange({
                                    type,
                                    value: v,
                                    key: 'receiver.feeFactor'
                                })}
                                minWidth='260px'
                                width='49%'
                                code
                            />
                        </Background>
                    </Flex>

                    <CMargin margin='4px' />
                    <Flex fw justify='flex-start'>
                        <Decoration />
                        <DText main>
                            <Text tid='available-order-note' />
                        </DText>
                    </Flex>
                    <CMargin margin='10px' />
                    <Flex fw wrap justify='space-between'>
                        <Background bg='detailBox'>
                            <Input
                                value={detail?.sender?.feeMax}
                                label={'sender-fee-max'}
                                onInputChange={(v) => onDetailChange({
                                    type,
                                    value: v,
                                    key: 'sender.feeMax'
                                })}
                                minWidth='260px'
                                width='49%'
                                currency={currency}
                                number
                            />
                            <Input
                                value={detail?.receiver?.feeMax}
                                label={'receiver-fee-max'}
                                onInputChange={(v) => onDetailChange({
                                    type,
                                    value: v,
                                    key: 'receiver.feeMax'
                                })}
                                minWidth='260px'
                                width='49%'
                                currency={currency}
                                number
                            />
                        </Background>
                    </Flex>
                    <Flex fw wrap justify='space-between'>
                        <Flex width={'50%'} justify={'flex-start'}>
                            <DText main>
                                <Text tid={'sender-is-active'} />:
                            </DText>
                            <Flex style={{ margin: '0 5px' }}>
                                <CheckBox
                                    active={detail?.sender.isActive}
                                    onClick={() => onDetailChange({
                                        type,
                                        key: 'sender.isActive',
                                        isBoolean: true
                                    })}
                                />
                            </Flex>
                        </Flex>
                        <Flex width={'50%'} justify={'flex-start'}>
                            <DText main>
                                <Text tid={'receiver-is-active'} />:
                            </DText>
                            <Flex style={{ margin: '0 5px' }}>
                                <CheckBox
                                    active={detail?.receiver.isActive}
                                    onClick={() => onDetailChange({
                                        type,
                                        key: 'receiver.isActive',
                                        isBoolean: true
                                    })}
                                />
                            </Flex>
                        </Flex>
                    </Flex>
                </CFlex>
            }
        </MotionFade>
    )
}


export default SwapUpdate
