import {useEffect, useState} from "react";
import {normalFetch} from "../../../../core/services/fetch-api/get";
import UserTag from "../../../common/utils/UserTag";
import {DetailsIcon, MemberRow, WorkspacePermItem} from "../../../../styles/main/reports";
import {CFlex, CMargin, DText, Flex} from "../../../../styles/CommonStyles";
import Text from "../../../../core/utils/Text";
import {LineBreak} from "../../../../styles/main/MainCommonStyles";
import BooleanIcon from "../../../common/utils/BooleanIcon";
import {useWindowSize} from "../../../../core/hooks/common/useWindowSize";
import ModalLayout from "../../../layout/main/ModalLayout";
import {ClipLoader} from "react-spinners";


const WorkspaceMembers = ({ members, loading }) => {

    const { width } = useWindowSize()


    const permInitial = { open: false, member: null }
    const [showPerms, setShowPerms] = useState(permInitial)
    const closePermModal = () => setShowPerms(permInitial)
    const openPermModal = (member) => setShowPerms({ open: true, member: member })

    const MOBILE = width <= 600

    return (
        <>
            <MemberRow>
                <DText primary fontSize={'s'}>
                    <Text tid={'user'} />
                </DText>
                <DText primary fontSize={'s'}>
                    <Text tid={'role'} />
                </DText>
                {!MOBILE &&
                    <PermissionHeader />
                }
            </MemberRow>
            <LineBreak />
            {loading ?
                <Flex fw height={'200px'}>
                    <ClipLoader color={'#ffc800'} />
                </Flex>
                :
                members?.map((item, idx) => (
                <>
                    <MemberRow>
                        <UserTag user={item.user} idx={idx} />
                        { MOBILE ?
                            <Flex justify={'space-between'}>
                                <DText main fontSize={'s'} lineBreak={'anywhere'}>
                                    {item.role}
                                </DText>
                                <DetailsIcon onClick={() => openPermModal(item)}/>
                            </Flex>
                            :
                            <DText main fontSize={'s'} lineBreak={'anywhere'}>
                                {item.role}
                            </DText>
                        }

                        {!MOBILE &&
                            <PermissionRow item={item}/>
                        }
                    </MemberRow>
                    <LineBreak />
                </>
            ))}

            <ModalLayout
                open={showPerms.open}
                onClose={closePermModal}
                width={'100%'}
            >
                <CFlex fw>
                    <PermissionHeader />
                    <CMargin margin={'4px'} />
                    <LineBreak />
                    <PermissionRow item={showPerms.member}/>
                </CFlex>
            </ModalLayout>
        </>
    )
}


const PermissionHeader = () => {

    return (
        <CFlex fw>
            <DText primary fontSize={'s'}>
                <Text tid={'permissions'} />
            </DText>
            <Flex fw>
                <WorkspacePermItem>
                    <Flex fw>
                        <DText main fontSize={'s'}>
                            <Text tid={'workspace-perm'} />
                        </DText>
                    </Flex>
                    <Flex fw justify={'space-around'}>
                        <DText main fontSize={'ss'}>
                            <Text tid={'edit'} />
                        </DText>
                        <DText main fontSize={'ss'}>
                            <Text tid={'withdraw'} />
                        </DText>
                        <DText main fontSize={'ss'}>
                            <Text tid={'delete'} />
                        </DText>
                    </Flex>
                </WorkspacePermItem>
                <WorkspacePermItem>
                    <Flex fw>
                        <DText main fontSize={'s'}>
                            <Text tid={'contract-perm'} />
                        </DText>
                    </Flex>
                    <Flex fw justify={'space-around'}>
                        <DText main fontSize={'ss'}>
                            <Text tid={'create'} />
                        </DText>
                        <DText main fontSize={'ss'}>
                            <Text tid={'edit'} />
                        </DText>
                    </Flex>
                </WorkspacePermItem>
                <WorkspacePermItem>
                    <Flex fw>
                        <DText main fontSize={'s'}>
                            <Text tid={'payment-perm'} />
                        </DText>
                    </Flex>
                    <Flex fw justify={'space-around'}>
                        <DText main fontSize={'ss'}>
                            <Text tid={'create'} />
                        </DText>
                        <DText main fontSize={'ss'}>
                            <Text tid={'edit'} />
                        </DText>
                        <DText main fontSize={'ss'}>
                            <Text tid={'approve'} />
                        </DText>
                    </Flex>
                </WorkspacePermItem>
            </Flex>
        </CFlex>
    )
}

const PermissionRow = ({item}) => {

    return (
        <Flex fw wrap>
            <WorkspacePermItem>
                <Flex fw justify={'space-around'}>
                    <BooleanIcon bool={item?.permissions?.workspace?.edit} />
                    <BooleanIcon bool={item?.permissions?.workspace?.withdraw} />
                    <BooleanIcon bool={item?.permissions?.workspace?.delete} />
                </Flex>
            </WorkspacePermItem>
            <WorkspacePermItem>
                <Flex fw justify={'space-around'}>
                    <BooleanIcon bool={item?.permissions?.contract?.create} />
                    <BooleanIcon bool={item?.permissions?.contract?.edit} />
                </Flex>
            </WorkspacePermItem>
            <WorkspacePermItem>
                <Flex fw justify={'space-around'}>
                    <BooleanIcon bool={item?.permissions?.payment?.create} />
                    <BooleanIcon bool={item?.permissions?.payment?.edit} />
                    <BooleanIcon bool={item?.permissions?.payment?.approve} />
                </Flex>
            </WorkspacePermItem>
        </Flex>
    )
}

export default WorkspaceMembers
