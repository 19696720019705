import {DetailCardHeader, DetailCardWrapper} from "../../../../styles/layout";
import {DText, Flex} from "../../../../styles/CommonStyles";
import Text from "../../../../core/utils/Text";
import {formatDate} from "../../../../core/utils/common";
import {useMainContext} from "../../../../core/contexts/main";


const DetailCard = ({
    header,
    date,
    children,
    ...rest
}) => {

    const { lang } = useMainContext()

    return (
        <DetailCardWrapper {...rest}>
            {header &&
                <DetailCardHeader>
                    <DText primary>
                        <Text tid={header} />
                    </DText>
                    {
                        date &&
                        <DText primary>
                            {`${formatDate(date, 'date', lang)}-${formatDate(date, 'time', lang)}`}
                        </DText>
                    }
                </DetailCardHeader>
            }
            <div style={{ padding: '6px 16px' }}>
                {children}
            </div>
        </DetailCardWrapper>
    )
}

export default DetailCard
