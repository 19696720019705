import { motion } from "framer-motion";
import styled, {css} from "styled-components";
import {FaCoins} from "react-icons/fa"
import {FiEdit} from 'react-icons/fi'
import {BiAddToQueue} from 'react-icons/bi'
import {IoIosAdd, IoIosArrowBack, IoIosArrowForward, IoIosEye} from 'react-icons/io'
import {MdPlaylistAdd, MdModeEdit} from 'react-icons/md'
import {HiOutlineDownload} from 'react-icons/hi'
import { keyframes } from "styled-components";
import {Flex} from "../../CommonStyles";


export const SettingCardWrapper = styled(motion.div)`
    width: ${props => props.width ? props.width : '100%'};
    height: ${props => props.height && props.height};
    border-radius: 2px;
    box-shadow: 0 0 8px rgb(0, 0, 0, 0.04);
    padding: 20px;
    background-color: ${props => props.theme.detailBox};
    min-height: ${props => props.minHeight || '300px'};
    min-width: 300px;
    margin: 10px 0;
    position: relative;

    

    @media screen and (max-width: 768px) {
        margin: 10px auto;
        width: 100%;
    };
`


export const AvailableCoinIcon = styled(FaCoins)`
    color: ${props => props.theme.color};
`


export const PriceBar = styled.div`
    width: 14px;
    border-radius: 2px 2px 0 0;
    height: 100%;
    border: 1px solid ${props => props.theme.color}30;
    margin: 0 6px;
    position: relative;

    &::after{
        content: ' ';
        height: ${props => props.height}%;
        width: 100%;
        border-radius: 2px 2px 0 0;
        position: absolute;
        background-color: ${props => props.color};
        left: 0;
        bottom: 0;
        z-index: 2;
    };
    
`


export const BarColor = styled.div`
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: ${props => props.color};
`

export const EditIcon = styled(FiEdit)`
    color: ${props => props.theme.color};
    cursor: pointer;
`


export const AddIcon = styled(BiAddToQueue)`
    color: ${props => props.theme.color};
    cursor: pointer;
`

export const TokenAddIcon = styled(IoIosAdd)`
    color: ${props => props.theme.color};
    cursor: pointer;
`


export const SubmitModalWrapper = styled(motion.div)`
    width: 300px;
    top: 80px;
  position: fixed;
  ${props => props.theme.english && css` 
    right: calc(50% - 250px);  
  `};
  
  ${props => !props.theme.english && css`
    left: calc(50% - 250px);
  `};
  
    border: 1px solid ${props => props.theme.color}10;
    background-color: ${props => props.theme.secondaryBg};
    padding: 20px 10px;
    box-shadow: 0 0 4px rgb(0, 0, 0, 0.05);
    z-index: 100;
    border-radius: 0 0 4px 4px;
  
  @media screen and (max-width: 768px) {
    ${props => props.theme.english && css` 
      right: calc(50% - 150px);  
    `};

    ${props => !props.theme.english && css`
      left: calc(50% - 150px);
    `};

  };
`

export const FixWrapper = styled.div`
  position: fixed;
  top: 80px;
  width: 100%;
`


export const EditWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 70px;
    width: 100%;
    height: 100%;
`


const fadeAnimation = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
`


export const AddNetworkBtn = styled.div`
    animation: ${fadeAnimation} 0.5s 0.2s forwards;
    width: 160px;
    height: 32px;
    border-radius: 2px;
    border: 1px solid ${props => props.theme.color}30;
    color: ${props => props.theme.color};
    font-size: 0.8rem;
    cursor: pointer;
    transition: all 0.275s;


    &:hover{
        background-color: ${props => props.theme.color};
        color: ${props => props.theme.secondaryBg};
    };
`


export const ModeDecoration = styled.div`
    background-color: ${props => props.editMode ? props.theme.mainOrange : props.theme.mainGreen}80;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    transition: all 0.25s;
`

export const ImageGetter = styled.input`
    display: block;
    position: absolute;
    width: 82px;
    height: 32px;
    opacity: 0;
    top: 0;
    left: 0;
    cursor: pointer;
    outline: none;
`

export const AddTokenIcon = styled.div`
  width: 102px;
  font-weight: 500;
  height: 32px;
  border-radius: 2px;
  background-color: #818F9F;
  font-size: 0.8rem;
  margin: 12px 0;
  cursor: pointer;
  color: black;
  position: relative;
`

export const DollarDetailBox = styled.div`

  min-width: 300px;
  width: 40%;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.color}10;
  margin: 10px 0;
  
  ${props => !props.update && css`
    background-image: linear-gradient(135deg, ${props => props.type === 'buy' ? '#819F82' : '#9F819E'}30 0%, ${props => props.theme.secondaryBg} 10%);
    padding: 20px 10px;
  `};

  ${props => props.update && css`
    background-color: ${props => props.theme.secondaryBg};
    padding: 20px;
  `};
`


export const UpdateCoinTagWrapper = styled.div`
  width: 180px;
  height: 42px;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.color}10;
  background-color: ${props => props.theme.secondaryBg};
  margin: 4px;
  padding: 0 8px;
  
  filter: brightness(${props => props.active ? 1 : 0.6});
  
  @media screen and (max-width: 480px) {
    width: 150px;
  };
`


export const UserLevelCard = styled.div`
  border: 1px solid ${props => props.theme.color}15;
  min-width: 400px;
  width: 30%;
  padding: 15px;
  background-color: ${props => props.theme.secondaryBg};
  margin: 20px 10px;
  border-radius: 4px;
  transition: all 0.25s;
  display: flex;
  flex-direction: column;
  min-height: 200px;
  
  ${props => props.add && css`
    filter: brightness(0.8);
    
    &:hover{
      filter: brightness(1);
    };
  `};
  
  @media screen and (max-width: 480px) {
    width: 100%;
    min-width: unset;
  };
`

export const UserLevelAddIcon = styled(MdPlaylistAdd)`
  color: ${props => props.theme.color};
`

export const PermTagWrapper = styled.div`
  min-width: 120px;
  height: 32px;
  border-radius: 4px;
  margin: 4px;
  padding: 0 6px;
  transition: all 0.2s;
  
  ${props => props.active && css`
    background-color: ${props => props.color}90;
  `};
  
  ${props => !props.active && css`
    background-color: ${props => props.theme.tInputBg}80;
    filter: brightness(0.8);
  `};
  
  border: 1px solid ${props => props.theme.color}10;
  
  &:hover{
    transform: scale(1.05);
  };
`


export const ReadIcon = styled(IoIosEye)`
  color: ${props => props.theme.color};
`

export const WriteIcon = styled(MdModeEdit)`
  color: ${props => props.theme.color};
`


export const RobotSettingCard = styled.div`
  min-width: 300px;
  width: 40%;
  border: 1px solid ${props => props.theme.color}10;
  background-color: ${props => props.theme.primaryBg};
  margin: 10px;
  padding: 10px 20px;
  border-radius: 4px;
`

export const CollapseLtr = styled(IoIosArrowForward)`
  color: ${props => props.theme.color};
  cursor: pointer;
  margin: 0 5px;
  
  transition: all 0.3s;
  transform: rotate(0deg);
  
  ${props => props.open && css`
    transform: rotate(90deg);
  `};
`

export const CollapseRtl = styled(IoIosArrowBack)`
  color: ${props => props.theme.primary};
  cursor: pointer;
  margin: 0 5px;

  transition: all 0.3s;
  transform: rotate(0deg);

  ${props => props.open && css`
    transform: rotate(-90deg);
  `};
`

export const DownloadIcon = styled(HiOutlineDownload)`
  color: ${props => props.theme.color};
`
