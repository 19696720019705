import {useNavigate} from "react-router-dom";
import {paymentAttributes} from "../../../../core/constants/headers";
import useGetTableIndex from "../../../../core/hooks/layout/useGetTableIndex";
import TableLayout from "../../../layout/main/TableLayout";
import {Column, Relative, Row} from "../../../../styles/CommonStyles";
import CoinRow from "../../../common/common/CoinRow";
import {formatNumber} from "../../../../core/utils/common";
import {TradeTypeBadge} from "../../../../styles/main/orders";
import Text from "../../../../core/utils/Text";
import {getTradeTypeColor} from "../../../../core/utils/theme";
import BooleanIcon from "../../../common/utils/BooleanIcon";
import DateTime from "../../../common/utils/DateTime";
import Operation from "../../../common/utils/Operation";
import NameTag from "../../../common/utils/NameTag";


const PaymentTable = ({ data }) => {

    const navigate = useNavigate()
    const {data: payments} = data
    const {headers, cs} = paymentAttributes
    const {getTableIndex} = useGetTableIndex()

    const onDetailsClicked = (contract) => navigate(`/reports/payment/${contract._id}`)


    return (
        <TableLayout
            data={data}
            cs={cs}
            headers={headers}
        >
            {payments?.data?.map((payment, idx) => (
                <Relative>
                    <Row cs={cs} index={idx}>
                        <Column>
                            {getTableIndex(idx)}
                        </Column>
                        <Column>
                            <NameTag name={payment.name} />
                        </Column>
                        <Column>
                            <CoinRow coin={{ currency: payment.currency }} />
                        </Column>
                        <Column>
                            {formatNumber(payment.amount)}
                        </Column>
                        <Column>
                            <TradeTypeBadge color={getTradeTypeColor(payment.status)}>
                                <Text tid={payment.status} />
                            </TradeTypeBadge>
                        </Column>
                        <Column center>
                            <BooleanIcon bool={payment.needApprove} />
                        </Column>
                        <Column center>
                            <BooleanIcon bool={payment.active} />
                        </Column>
                        <Column center>
                            <DateTime dt={payment.dueDate} />
                        </Column>
                        <Column center>
                            <DateTime dt={payment.createdAt} />
                        </Column>
                    </Row>
                    <Column operation>
                        <Operation
                            onDetailsClicked={() => onDetailsClicked(payment)}
                            hasDetails
                        />
                    </Column>
                </Relative>
            ))}
        </TableLayout>
    )
}

export default PaymentTable
