import TableLayout from "../../../layout/main/TableLayout";
import {blockedAttributes} from "../../../../core/constants/headers";
import {Column, Row} from "../../../../styles/CommonStyles";
import useGetTableIndex from "../../../../core/hooks/layout/useGetTableIndex";
import UserLink from "../../../common/utils/UserLink";
import DateTime from "../../../common/utils/DateTime";


const BlockedUsersTable = ({ data }) => {

    const { headers, cs } = blockedAttributes
    const { getTableIndex } = useGetTableIndex()

    return (
        <TableLayout
            data={data}
            cs={cs}
            headers={headers}
        >
            {data?.data?.data?.map((item, idx) => {

                return (
                    <Row cs={cs} key={item._id}>
                        <Column>
                            {getTableIndex(idx)}
                        </Column>
                        <Column>
                            <UserLink _id={item.entity?._id} name={item.entity?.email} />
                        </Column>
                        <Column>
                            <UserLink _id={item.blocked?._id} name={item.blocked?.email} />
                        </Column>
                        <Column center>
                            <DateTime dt={item.createdAt} />
                        </Column>
                    </Row>
                )
            })}
        </TableLayout>
    )
}

export default BlockedUsersTable
