import TableLayout from "../../layout/main/TableLayout";
import {Column, DText, Flex, Padding, Relative, Row} from "../../../styles/CommonStyles";
import {formatNumber, getNames} from "../../../core/utils/common";
import {DocumentStatus, UserStatus} from "../../../styles/main/profile";
import Text from "../../../core/utils/Text";
import Operation from "../../common/utils/Operation";
import Avatar from "../../common/utils/Avatar";
import useUsersList from "../../../core/hooks/main/users/useUsersList";
import useGetTableIndex from "../../../core/hooks/layout/useGetTableIndex";
import {acceptUserOperations, rejectUserOperations} from "../../../core/constants/operation";
import {userAttributes} from "../../../core/constants/headers";
import {TradeTypeBadge} from "../../../styles/main/orders";
import {getTradeTypeColor} from "../../../core/utils/theme";
import BooleanIcon from "../../common/utils/BooleanIcon";


const UsersTable = ({
    data
}) => {

    const { data: users } = data
    const { cs, headers } = userAttributes
    const { getTableIndex } = useGetTableIndex()

    const {
        hasWriteAccess,
        onOptionClicked,
        onDetailsClicked
    } = useUsersList()

    return (
        <TableLayout
            headers={headers}
            cs={cs}
            data={data}
            hasWriteAccess={hasWriteAccess}
        >
            {users?.data?.map((user, idx) => {

                return(
                    <Relative>
                        <Row cs={cs} index={idx} key={user._id}>
                            <Column>
                                {getTableIndex(idx)}
                            </Column>
                            <Column>
                                <DText fontSize={'s'} main>
                                    {user.email}
                                </DText>
                            </Column>
                            <Column>
                                {user.code}
                            </Column>
                            <Column>
                                <TradeTypeBadge color={getTradeTypeColor(user.type)} >
                                    {user.type}
                                </TradeTypeBadge>
                            </Column>
                            <Column>
                                <TradeTypeBadge color={getTradeTypeColor(user.role)} >
                                    {user.role}
                                </TradeTypeBadge>
                            </Column>
                            <Column>
                                {user.ga?.status}
                            </Column>
                            <Column>
                                <Padding padding={'0 8px'}>
                                    <BooleanIcon bool={user.isActive} />
                                </Padding>
                            </Column>
                        </Row>
                        <Column operation>
                            <Operation
                                options={user.verifyAt ? rejectUserOperations : acceptUserOperations}
                                onOptionClicked={(idx) => onOptionClicked(idx, user)}
                                onDetailsClicked={() => onDetailsClicked(user)}
                                hasWriteAccess={hasWriteAccess}
                                hasDetails
                            />
                        </Column>
                    </Relative>

                )
            })}
        </TableLayout>
    )
}



export default UsersTable
