import ProfileInfo from "../profile/ProfileInfo";
import {Flex} from "../../../styles/CommonStyles";
import UserBalances from "./elements/UserBalances";
import {useParams} from "react-router-dom";
import {useGetAUser} from "../../../core/services/react-query/user";
import {useEffect} from "react";



const UserSubpages = ({
    activeTab
}) => {

    const params = useParams()
    const { data: user, refetch } = useGetAUser(params?.id)
    useEffect(() => {
        refetch()
    }, [])

    const subpages = [
        ProfileInfo,
        UserBalances,
        // ProfileBanks,
        // ProfileWallets,
        // UserCurrentOrders,
        // UserTrades,
        // UserOtcTrades,
        // UserCoinTransactions,
        // UserBankTransactions,
        // UserActivities,
        // UserSetting
    ]
    const Subpage = subpages[activeTab]

    return (
        <Flex align={'flex-start'} fw>
            <Subpage
                detail
                user={user}
                refetch={refetch}
            />
        </Flex>
    )
}


export default UserSubpages
