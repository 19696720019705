import {DText, Flex} from "../../../../styles/CommonStyles";
import Text from "../../../../core/utils/Text";


const DetailItem = ({ prefix, suffix }) => {

    return (
        <Flex justify={'flex-start'} style={{ minWidth: '180px', margin: '4px 0' }} wrap>
            <DText main>
                <Text tid={prefix} /> :
            </DText>
            <Flex style={{ margin: '0 6px' }}>
                {typeof  suffix === 'string' ?
                    <DText primary>
                        <Text tid={suffix} />
                    </DText>
                    :
                    suffix
                }
            </Flex>
        </Flex>
    )
}

export default DetailItem
