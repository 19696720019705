import FilterLayout from "../../components/layout/filter/FilterLayout";
import {Flex, IconWrapper, PageCard} from "../../styles/CommonStyles";
import ListLayout from "../../components/layout/main/ListLayout";
import {CacheKeys, userFilterOptions, userSortOptions} from "../../core/constants/filter";
import UsersTable from "../../components/main/users/UsersTable";
import { useUsersQuery} from "../../core/services/react-query/user";
import {useState} from "react";
import {useWindowSize} from "../../core/hooks/common/useWindowSize";
import {TABLET_SIZE} from "../../core/constants/common";
import RUserTable from "../../components/responsive/main/users/RUserTable";
import {useAclContext} from "../../core/contexts/acl";
import Tooltip from "../../components/common/utils/Tooltip";
import {AddIcon} from "../../styles/main/setting";
import {useNavigate} from "react-router-dom";


const Users = () => {

    const navigate = useNavigate()
    const { width } = useWindowSize()

    const {permissions} = useAclContext()
    const hasDownloadAccess = permissions?.export?.write
    const hasAddUser = permissions?.user?.write
    const [ users, setUsers ] = useState({data: null, loading: true})

    const onUserQuery = (res) => {
        setUsers(res)
    }

    const Component = width > TABLET_SIZE ? UsersTable : RUserTable

    const onAddUser = () => {
        navigate('/users/add-user')
    }

    return (
        <ListLayout>
            <PageCard>
                {hasAddUser &&
                    <Flex fw justify={'flex-end'}>
                        <Tooltip
                            content={'add-user'}
                        >
                            <IconWrapper onClick={onAddUser}>
                                <AddIcon size={24} />
                            </IconWrapper>
                        </Tooltip>
                    </Flex>
                }

                <FilterLayout
                    query={useUsersQuery}
                    options={userFilterOptions}
                    onQuerySuccess={onUserQuery}
                    cache={CacheKeys.USER}
                    hasDownload={hasDownloadAccess}
                    sortOptions={userSortOptions}
                >
                    {width &&
                        <Component
                            data={users}
                        />
                    }

                </FilterLayout>
            </PageCard>
        </ListLayout>
    )
}

export default Users
