import {useQuery} from "react-query";
import {useMainContext} from "../../../../contexts/main";
import {makeQueryFilters} from "../../../../utils/query-maker";
import {filterFetch} from "../../../fetch-api/get";


export const attachmentsKeys = {
    listAllAttachments: 'listAllAttachments'
}

export const useListAttachments = (filters, pagination, extra, csv) => {

    const { lang } = useMainContext()
    const params = {...makeQueryFilters(filters, lang), ...pagination}
    if (csv){
        params.export = 'csv'
    }

    return useQuery(
        attachmentsKeys.listAllAttachments, () => filterFetch(params, 'attachments/admin')
    )
}
