import ListLayout from "../../../components/layout/main/ListLayout";
import {CMargin, DText, Flex, PageCard} from "../../../styles/CommonStyles";
import {useAclContext} from "../../../core/contexts/acl";
import NoAccess from "../../../components/layout/main/NoAccess";
import {useGetConfigItems} from "../../../core/services/react-query/setting";
import {useEffect, useState} from "react";
import ThirdBalances from "../../../components/main/setting/otc/details/ThirdBalances";
import Text from "../../../core/utils/Text";


const OtcSettingDetails = () => {

    const { data: config } = useGetConfigItems()

    const { permissions } = useAclContext()
    const hasReadAccess = permissions.thirdParty?.read

    const [third, setThird] = useState(null)

    useEffect(() => config && setThird(config.third.default),[config])

    if (!hasReadAccess) {
        return <NoAccess />
    }

    return (
        <ListLayout>
            <PageCard>
                <Flex fw justify={'flex-start'}>
                    <DText primary>
                        <Text tid={'balances'} /> {third}
                    </DText>
                </Flex>
                <CMargin margin={'6px'} />
                <ThirdBalances
                    third={third}
                />
            </PageCard>
        </ListLayout>
    )
}

export default OtcSettingDetails
