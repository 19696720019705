export const formatDate = (date, type, lang) => {
    if (type === 'time') {
        const formatted = new Date(date).toLocaleTimeString(lang, {hour12: false})
        return convertNumbers2English(formatted)
    }
    const formatted = new Date(date).toLocaleDateString(lang, {hour12: false})
    return convertNumbers2English(formatted)
}


const convertNumbers2English = function(string) {
    return string.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (c) => {
        return c.charCodeAt(0) - 1632;
    }).replace(/[۰۱۲۳۴۵۶۷۸۹]/g, (c) => {
        return c.charCodeAt(0) - 1776;
    });
}

export const variants = {
    in: {
        opacity: 1, transition: { duration: 0.3 }
    },
    out: {
        opacity: 0, transition: { duration: 0.3 }
    }
}

const toPlainString = (num) => {
    return (''+ +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/,
        function(a,b,c,d,e) {
            return e < 0
                ? b + '0.' + Array(1-e-c.length).join(0) + c + d
                : b + c + d + Array(e-d.length+1).join(0);
        });
}


/**
 * Format numbers - filter
 * @param {number | float} num
 * @param {number} point
 * @param { string } type
 * @returns {string|number}
 */
export const formatNumber = (
    num,
    {point = 0, type= ''} = {}
) => {
    if (!num) {
        return 0
    }
    // const float = parseFloat(num)

    let number = num.toString()
    if (number.includes('e')) number = toPlainString(num)
    const numParts = number.split('.')

    const firstPart = numParts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

    if (type === 'irt') return firstPart

    let secondPart = null;
    if (numParts.length > 1) {
        secondPart = numParts[1].toString().substring(0, point ? point : (type === 'usdt' ? 2 : 6))
    }

    let formattedNumber = firstPart
    if (secondPart) formattedNumber = `${firstPart}.${secondPart}`
    if (!secondPart && point) formattedNumber += '.00'
    if (point && secondPart) {
        for (let i = 0; i < point - secondPart.length; i++) {
            formattedNumber += '0'
        }
    }

    if (!point && secondPart) {
        for (let i = formattedNumber.length - 1; i > 0; i--) {
            if (formattedNumber[i] === '0') {
                formattedNumber = formattedNumber.substring(0, i)
            }else if (formattedNumber[i] === '.'){
                formattedNumber = formattedNumber.substring(0, i)
                break
            }else {
                break
            }
        }
    }

    return formattedNumber

    // if (Math.abs(float) < 0.000001) return float.toFixed(8)
    // if (Math.abs(float) < 0.01) return float.toFixed(point ? point : 6)
    // if (Math.abs(float) < 1) return float.toFixed(point ? point : 6)
    // if (Math.abs(float) < 1000) return float.toFixed(point ? point : 1)
    // return Math.round(float).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const formatSize = (size) => {
    if (size < 1000) return `${size}B`
    if (size < 1000000) return `${(size / 1000).toFixed(2)}KB`
    else return `${(size / 1000000).toFixed(2)}MB`
}

export const stringToNumber = (str) => {
    try {
        let numParts = str.split('.')
        numParts[0] = numParts[0].replaceAll(",", "")
        return parseFloat(numParts.join("."))
    } catch (err) { return str }
}


export const p2e = (str) => {
    return str.replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))
}

export const getNames = (firstName, lastName) => {
    let FULLNAME = firstName ? `${firstName} ` : ''
    FULLNAME += lastName ? lastName : ''

    const NAMEAVATAR =
        firstName ? firstName : (
            lastName ? lastName : 'U'
        )

    return {
        FULLNAME, NAMEAVATAR
    }
}


export const deepCopy = (obj) => {
    if (!obj) return null
    return JSON.parse(JSON.stringify(obj))
}

export const formatObjectNumbers = (obj) => {
    const temp = {...obj}
    Object.keys(obj).map(key => {
        const value = obj[key]
        if (typeof value === 'string') {
            const numberValue = stringToNumber(value)
            temp[key] = numberValue ? numberValue : 0
        }else {
            temp[key] = value
        }
    })
    return temp
}


export const makeObjectFormattedNumber = (obj) => {
    if (!obj) return {}
    const temp = {...obj}
    Object.keys(obj).map(key => {
        const value = obj[key]
        if (typeof value === 'number') {
            const numberValue = formatNumber(value)
            temp[key] = numberValue ? numberValue : 0
        }
    })
    return temp
}


export const fileDownloader = ({ data, fileName, fileType }) => {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType })
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
}


export async function downloadImage(imageSrc) {
    const image = await fetch(imageSrc)
    const imageBlog = await image.blob()
    const imageURL = URL.createObjectURL(imageBlog)

    const link = document.createElement('a')
    link.href = imageURL
    link.download = 'image file name here'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}


export const formatCardNumber = (cardNo) => {
    let res = ''
    for (let i = 0; i < cardNo.length; i++ ) {
        if (i !== 0 && i % 4 === 0) {
            res += '-'
        }
        res += cardNo[i]
    }
    return res
}


export const formatAuthDate = (date) => {
    const month = date.month < 10 ? `0${date.month}` : date.month
    const day = date.day < 10 ? `0${date.day}` : date.day
    return `${date.year}-${month}-${day}`
}
