import {deepCopy} from "./common";
import {validEmailRegex} from "../constants/common";


export const numberInputTypes = [
    'mobile', 'phone',
    'meliCode', 'zip'
]

export const inputLength = {
    meliCode: 10,
    mobile: 11,
    phone: 11,
    zip: 10
}


export const validateMeliCode = (input) => {
    if (
        !/^\d{10}$/.test(input) ||
        input === "0000000000" ||
        input === "1111111111" ||
        input === "2222222222" ||
        input === "3333333333" ||
        input === "4444444444" ||
        input === "5555555555" ||
        input === "6666666666" ||
        input === "7777777777" ||
        input === "8888888888" ||
        input === "9999999999"
    )
        return false;
    var check = parseInt(input[9]);
    var sum = 0;
    var i;
    for (i = 0; i < 9; ++i) {
        sum += parseInt(input[i]) * (10 - i);
    }
    sum %= 11;
    return (sum < 2 && check === sum) || (sum >= 2 && check + sum === 11);
};


export const validateProfileInfo = (key, value, validationStatus) => {

    const newValidationStatus = deepCopy(validationStatus)

    if (
        key === 'firstName' ||
        key === 'lastName' ||
        key === 'fatherName' ||
        key === 'county' ||
        key === 'city' ||
        key === 'address'
    ) {
        newValidationStatus[key].valid = value.length >= 3
    }
    if (key === 'meliCode') {
        newValidationStatus[key].valid = validateMeliCode(value)
    }
    if (key === 'email') {
        newValidationStatus[key].valid = validEmailRegex.test(value)
    }
    if (key === 'mobile' || key === 'phone') {
        newValidationStatus[key].valid = value.length && value.length === 11 && value[0] === '0'
    }
    if (key === 'zip') {
        newValidationStatus[key].valid = value.length === 10
    }

    return newValidationStatus
}
