import {Background, CFlex, CMargin, DText, Flex, Padding} from "../../../../../styles/CommonStyles"
import MotionFade from "../../../../common/utils/MotionFade"
import Input from "../../../../common/input/Input"
import {AC_SETTING_TYPES} from "../../../../../core/hooks/main/setting/currencies/useCurrencySetting";
import Text from "../../../../../core/utils/Text";
import {SOCKET_URL} from "../../../../../core/constants/urls";
import Select from "../../../../common/dropdown/Select";
import ColorPicker from "../../../../common/input/ColorPicker";




const DetailUpdate = ({
    coin,
    onDetailChange
}) => {

    const currencyTypes = ['crypto', 'fiat']

    const Item = ({ prefix, suffix }) => {
        return (
            <Flex fw justify={'space-between'}>
                <DText main>
                    <Text tid={prefix} />:
                </DText>
                <DText primary margin={'0 5px'}>
                    {suffix}
                </DText>
            </Flex>
        )
    }

    return (
        <MotionFade>
            <Flex justify='space-around' fw wrap>
                <CFlex width={'300px'} justify={'space-between'} align={'space-between'}>
                    <CMargin margin={'8px'} />
                    <Item
                        prefix={'icon'}
                        suffix={
                            <img
                                src={SOCKET_URL + `icons/${coin?.currency}.png`}
                                width='28px'
                                alt={' '}
                            />
                        }
                    />
                    <CMargin margin={'6px'} />
                    <Background bg={'detailBox'}>
                        <Input
                            value={coin?.currency}
                            label={'coin'}
                            height={'32px'}
                            size={'small'}
                            onInputChange={(v) => onDetailChange({
                                type: AC_SETTING_TYPES.DETAILS,
                                value: v,
                                key: 'currency'
                            })}
                        />
                    </Background>
                    <CMargin margin={'6px'} />
                    <Background bg={'detailBox'}>
                        <Input
                            value={coin?.name}
                            label={'name'}
                            height={'32px'}
                            size={'small'}
                            onInputChange={(v) => onDetailChange({
                                type: AC_SETTING_TYPES.DETAILS,
                                value: v,
                                key: 'name'
                            })}
                        />
                    </Background>
                </CFlex>
                <CFlex width={'300px'} justify={'space-between'} align={'space-between'}>
                    <CMargin margin={'8px'} />
                    <Item
                        prefix={'color'}
                        suffix={
                            <ColorPicker
                                color={coin?.color}
                                onChange={(v) => onDetailChange({
                                    type: AC_SETTING_TYPES.DETAILS,
                                    value: v,
                                    key: 'color'
                                })}
                            />
                        }
                    />
                    <CMargin margin={'6px'} />
                    <Select
                        options={currencyTypes}
                        value={coin?.type}
                        onValueChange={(idx) => onDetailChange({
                            type: AC_SETTING_TYPES.DETAILS,
                            value: currencyTypes[idx],
                            key: 'type'
                        })}
                        height={'42px'}
                        size={'small'}
                    />
                    <CMargin margin={'6px'} />
                    <Background bg={'detailBox'}>
                        <Input
                            value={coin?.decimal}
                            code
                            label={'decimal'}
                            height={'32px'}
                            size={'small'}
                            onInputChange={(v) => onDetailChange({
                                type: AC_SETTING_TYPES.DETAILS,
                                value: v,
                                key: 'decimal'
                            })}
                        />
                    </Background>
                </CFlex>
            </Flex>
        </MotionFade>
    )
}


export default DetailUpdate
