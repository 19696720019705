import {wagesAttributes} from "../../../../../core/constants/headers";
import useWagesList from "../../../../../core/hooks/main/wages-list/useWagesList";
import Select from "../../../../common/dropdown/Select";
import TableLayout from "../../../../layout/main/TableLayout";
import {Column, Row} from "../../../../../styles/CommonStyles";
import CoinRow from "../../../../common/common/CoinRow";
import {formatNumber} from "../../../../../core/utils/common";
import Operation from "../../../../common/utils/Operation";


const WagesTable = () => {

    //TODO: handy sort
    const { cs, headers } = wagesAttributes
    const {
        wages,
        wagesList,
        loading,
        availableCoins,
        coin,
        onCoinChange,
        onDetailsClicked
    } = useWagesList()

    return (
        <>
            <Select
                options={availableCoins}
                value={coin}
                onValueChange={onCoinChange}
                placeHolder={'search'}
                width={'280px'}
                height={'42px'}
                isCoin
            />
            <TableLayout
                headers={headers}
                cs={cs}
                data={{data: wagesList, loading}}
                hasPagination={false}
                hasWriteAccess
            >
                {wages?.map((wage, idx) => (

                    <Row cs={cs} index={idx} key={wage.currency}>
                        <Column>
                            {idx + 1}
                        </Column>
                        <Column>
                            <CoinRow coin={{ currency: wage.currency }} />
                        </Column>
                        <Column>
                            {formatNumber(wage.count)}
                        </Column>
                        <Column style={{ lineBreak: 'anywhere' }}>
                            {formatNumber(wage.amount)}
                        </Column>
                        <Column>
                            {formatNumber(wage.wages)}
                        </Column>
                        <Column>
                            <Operation
                                onDetailsClicked={() => onDetailsClicked(wage)}
                                hasDetails
                            />
                        </Column>
                    </Row>
                ))}
            </TableLayout>
        </>

    )
}

export default WagesTable
