import MotionFade from "../../../../common/utils/MotionFade";
import {useGetThirdBalances} from "../../../../../core/services/react-query/setting/thirdparty";
import {useEffect} from "react";
import TableLayout from "../../../../layout/main/TableLayout";
import {Column, DText, Flex, Row} from "../../../../../styles/CommonStyles";
import {SOCKET_URL} from "../../../../../core/constants/urls";
import {TradeTypeBadge} from "../../../../../styles/main/orders";
import {getTradeTypeColor} from "../../../../../core/utils/theme";
import {formatNumber} from "../../../../../core/utils/common";
import {thirdBalanceAttributes} from "../../../../../core/constants/headers";


const ThirdBalances = ({
    third
}) => {
    const { data: balances, refetch: getBalances, isFetching: fetchingBalances } = useGetThirdBalances(third)
    const { headers, cs } = thirdBalanceAttributes
    useEffect(() => {
        if (third) getBalances()
    },[third])

    return (
        <MotionFade>
            <div style={{ height: '20px' }} />
            <TableLayout
                headers={headers}
                cs={cs}
                data={{ data: balances, loading: fetchingBalances }}
                hasPagination={false}
            >
                {balances?.data?.map((balance, idx) => (
                    <Row key={balance?._id} index={idx} cs={cs}>
                        <Column>
                            {idx + 1}
                        </Column>
                        <Column>
                            <Flex>
                                <img
                                    src={SOCKET_URL + `assets/icon/${balance.currency?.toLowerCase()}.png`}
                                    width={'28px'}
                                    height={'28px'}
                                    alt={' '}
                                />
                                <DText main fontSize={'s'} style={{ margin: '0 5px' }}>
                                    {balance.currency}
                                </DText>
                            </Flex>
                        </Column>
                        <Column>
                            <TradeTypeBadge color={getTradeTypeColor('limit')}>
                                {balance.type}
                            </TradeTypeBadge>
                        </Column>
                        <Column>
                            <DText main fontSize={'s'}>
                                {formatNumber(balance.balance)}
                            </DText>
                        </Column>
                        <Column>
                            <DText main fontSize={'s'}>
                                {formatNumber(balance.available)}
                            </DText>
                        </Column>
                        <Column>
                            <DText main fontSize={'s'}>
                                {formatNumber(balance.holds)}
                            </DText>
                        </Column>
                    </Row>
                ))}
            </TableLayout>
        </MotionFade>
    )
}



export default ThirdBalances
