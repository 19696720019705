import {DText, Flex} from "../../../styles/CommonStyles";
import {SOCKET_URL} from "../../../core/constants/urls";



const CoinRow = ({coin}) => {

    return (
        <Flex justify={'flex-start'}>

            <img
                src={SOCKET_URL + `icons/${coin?.currency}.png`}
                width='28px'
                alt={' '}
                style={{ margin: '0 5px' }}
                // crossOrigin={'anonymous'}
            />
            <DText main style={{ margin: '0 5px' }} fontSize={'s'}>
                {coin?.name || coin?.currency?.toUpperCase()}
            </DText>

        </Flex>
    )
}




export default CoinRow
