import { useMutation} from "react-query";
import {postApi} from "../../fetch-api/post";
import {useQueryContext} from "../../../contexts/query";
import {useNavigate} from "react-router-dom";
import {useMainContext} from "../../../contexts/main";
import {deleteApi} from "../../fetch-api/delete";
import useAsyncError from "../../../hooks/common/useAsyncError";
import {PROFILE_KEYS} from "../profile";
import {useAclContext} from "../../../contexts/acl";


const AUTH_KEYS = {
    REQUEST_OTP: 'request-otp',
    VERIFY_OTP: 'verify-otp',
    VERIFY_2FA: 'verify-2fa',
    LOGOUT: 'logout',
    REFRESH_TOKEN: 'refresh-token'
}


const useRequestOtp = (onSuccess, onError) => {

    const throwError = useAsyncError()
    return useMutation(
        AUTH_KEYS.REQUEST_OTP, (data) => postApi(data, 'auth/login', throwError),
        {
            onSuccess,
            onError
        }
    )
}


const useVerifyOtp = (onSuccess, onError) => {

    const navigate = useNavigate()
    const { queryClient } = useQueryContext()
    const { setToken, setRefreshToken } = useMainContext()
    const { setRole } = useAclContext()

    return useMutation(
        AUTH_KEYS.VERIFY_OTP, (data) => postApi(data, 'auth/login/complete'),
        {
            onSuccess: (res) => {
                setToken(res.data?.data?.accessToken)
                setRefreshToken(res?.data?.data?.refreshToken)
                setRole(res?.data?.data?.user?.role)
                navigate('/')
                // queryClient.invalidateQueries('fetch-profile')
            },
            onError
        }
    )
}


const useVerify2fa = (onError) => {

    const navigate = useNavigate()
    const { queryClient } = useQueryContext()
    const { setToken } = useMainContext()
    const { setRole } = useAclContext()

    return useMutation(
        AUTH_KEYS.VERIFY_OTP, (data) => postApi(data, 'auth/2fa/verify'),
        {
            onSuccess: (res) => {
                setToken(res.headers['x-auth-token'])
                setRole(res?.data?.data?.user?.role)
                navigate('/')
                queryClient.invalidateQueries('fetch-profile')

            },
            onError
        }
    )
}


const useRefreshToken = () => {
    const { setToken, setRefreshToken } = useMainContext()
    const mutate = (data) => {
        return postApi(data, 'auth/refreshToken')
            .then(res => {
                setToken(res?.data?.data?.accessToken)
                setRefreshToken(res?.data?.data?.refreshToken)
            }).catch(err => {
                console.log(err, 'refresh token fail')
            })
    }

    return { mutate }
}


const useLogout = () => {

    const navigate = useNavigate()
    const { setToken } = useMainContext()
    const { setToast, queryCache } = useQueryContext()
    const { reset, queryClient } = useAclContext()

    return useMutation(
        AUTH_KEYS.LOGOUT, () => deleteApi('auth/token'),
        {
            onSuccess: () => {
                setToast({
                    show: true,
                    message: 'logout-success'
                })
                setToken('')
                reset()
                queryCache?.clear()
                navigate('/login')
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            }
        }
    )
}

export {
    useRequestOtp,
    useVerifyOtp,
    useVerify2fa,
    useLogout,
    useRefreshToken
}
