import {Background} from "../../../styles/CommonStyles";
import Input from "./Input";


const DateInput = ({value, openCalendar, handleValueChange, label, isProfile = false}) => {

    return (
        <div onClick={openCalendar}>
            <Background bg={isProfile ? 'mainBg' : 'primaryBg'}>
                <Input
                    value={value}
                    onInputChange={handleValueChange}
                    size={isProfile ? 'big' : 'small'}
                    label={label}
                />
            </Background>
        </div>
    )
}


export default DateInput
