


const RAttachmentsTable = () => {

    return (
        <>
        </>
    )
}

export default RAttachmentsTable
