import MotionFade from "../../../../common/utils/MotionFade";
import {Absolute, Background, CMargin, Decoration, DText, Flex} from "../../../../../styles/CommonStyles";
import Text from "../../../../../core/utils/Text";
import Input from "../../../../common/input/Input";
import useAffiliateSetting from "../../../../../core/hooks/main/setting/general/useAffiliateSetting";
import ABActionButton from "../ABActionButton";
import {useWindowSize} from "../../../../../core/hooks/common/useWindowSize";
import {MOBILE_SIZE} from "../../../../../core/constants/common";


const AffiliateSetting = () => {

    const { width } = useWindowSize()

    const {
        affiliatePc,
        onInputValueChange,
        loading,
        detailsChanged,
        onSubmitClicked
    } = useAffiliateSetting()

    return (
        <MotionFade>
            <CMargin margin={'30px'} />
            <Flex fw justify={'flex-start'}>
                <Decoration />
                <DText main>
                    <Text tid={'affiliate-setting-note'} />
                </DText>
            </Flex>
            <CMargin margin={'20px'} />
            <Flex fw wrap justify={'flex-start'}>
                <Background bg={'mainBg'}>
                    <Input
                        value={affiliatePc}
                        onInputChange={onInputValueChange}
                        label={'affiliate-pc'}
                        width={'300px'}
                        number
                    />
                </Background>
            </Flex>

            {width > MOBILE_SIZE ?
                <ABActionButton
                    show={detailsChanged}
                    onClick={onSubmitClicked}
                    loading={loading}
                />
                :
                <Absolute style={{ position: 'fixed' }} left={0} bottom={0} height={'60px'}>
                    <ABActionButton
                        show={detailsChanged}
                        onClick={onSubmitClicked}
                        loading={loading}
                    />
                </Absolute>
            }
        </MotionFade>
    )
}


export default AffiliateSetting
