import TableLayout from "../../layout/main/TableLayout";
import {Column, DText, Row} from "../../../styles/CommonStyles";
import useGetTableIndex from "../../../core/hooks/layout/useGetTableIndex";
import {TradeTypeBadge} from "../../../styles/main/orders";
import {getTradeTypeColor} from "../../../core/utils/theme";
import Text from "../../../core/utils/Text";
import UserLink from "../../common/utils/UserLink";
import {walletAttributes} from "../../../core/constants/headers";
import NameTag from "../../common/utils/NameTag";
import CoinRow from "../../common/common/CoinRow";
import {formatNumber} from "../../../core/utils/common";


const WalletsTable = ({ data }) => {

    const { cs, headers } = walletAttributes
    const { getTableIndex } = useGetTableIndex()
    const { data: wallets } = data


    return (
        <>
            <TableLayout
                headers={headers}
                cs={cs}
                data={data}
            >
                {wallets?.data?.map((wallet, idx) => {

                    return (
                        <>
                            <Row index={idx} cs={cs}>
                                <Column>
                                    {getTableIndex(idx)}
                                </Column>
                                <Column>
                                    <UserLink _id={wallet.userId} name={wallet.user.email} />
                                </Column>
                                <Column>
                                    <NameTag name={wallet.address || ""} />
                                </Column>
                                <Column>
                                    <CoinRow coin={{ currency: wallet.currency }} />
                                </Column>
                                <Column>
                                    <TradeTypeBadge
                                        color={'#ffc80090'}>
                                        {wallet.network}
                                    </TradeTypeBadge>
                                </Column>
                                <Column>
                                    <DText primary margin={'0 10px'}>
                                        {formatNumber(wallet.amount)}
                                    </DText>
                                </Column>
                                <Column>
                                    <TradeTypeBadge
                                        color={getTradeTypeColor(wallet.flow)}>
                                        <Text tid={wallet.flow}/>
                                    </TradeTypeBadge>
                                </Column>
                            </Row>
                        </>
                        )})}
            </TableLayout>

        </>
    )
}


export default WalletsTable
