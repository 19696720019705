import { Suspense } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate
} from "react-router-dom"

import GlobalLoading from "../components/common/loading/GlobalLoading";
import Dashboard from "./dashboard/Dashboard";
import Profile from "./profile/Profile";
import Login from "./Login";
import {useSetAxiosHeaders} from "../core/hooks/common/useSettings";
import MainLayout from "../components/layout/main/MainLayout";
import Users from "./users/Users";
import AclContextProvider from "../core/contexts/acl";
import Affiliates from "./reports/affiliates/Affiliates";
import Tokens from "./setting/tokens/Tokens";
import Pairs from "./setting/pairs/Pairs";
import Roles from "./setting/roles/Roles";
import Robots from "./setting/robots/Robots";
import Otc from "./setting/otc/Otc";
import General from "./setting/general/General";
import UserDetails from "./users/UserDetails";
import Reports from "./reports";
import Settings from "./setting";
import TokenDetails from "./setting/tokens/TokenDetails";
import TokenOperation from "./setting/tokens/TokenOperation";
import Fiat from "./setting/fiat/Fiat";
import OtcSettingDetails from "./setting/otc/OtcSettingDetails";
import Cartable from "./cartable/Cartable";
import CartableDetails from "./cartable/CartableDetails";
import AddUser from "./users/AddUser";
import Addresses from "./reports/address/Addresses";
import UserSubDetails from "./users/UserSubDetails";
import {useMainContext} from "../core/contexts/main";
import Wallets from "./wallets/Wallets";
import Currencies from "./setting/currencies/Currencies";
import Contract from "./reports/contract/Contract";
import ContractDetails from "./reports/contract/ContractDetails";
import Workspace from "./reports/workspace/Workspace";
import WorkspaceDetails from "./reports/workspace/WorkspaceDetails";
import Payment from "./reports/payment/Payment";
import PaymentDetails from "./reports/payment/PaymentDetails";
import TraceMain from "./reports/trace/TraceMain";
import BlockedUsers from "./reports/blocked-users/BlockedUsers";
import Attachments from "./reports/attachments/Attachments";



const Index = () => {

    // setting headers
    useSetAxiosHeaders()
    const { token } = useMainContext()

    return (
        <AclContextProvider>
            <Router basename={process.env.PUBLIC_URL}>
                <Suspense fallback={<GlobalLoading />}>
                    <MainLayout>
                        {!!token ?
                            <Routes>
                                {routes.map(route => {

                                    const {path, Component} = route
                                    return <Route key={path} path={path} element={<Component />} />
                                })}
                                <Route path={'*'}  element={<Navigate to="/profile" replace />} />
                            </Routes>
                            :
                            <Routes>
                                <Route key={'login'} path={'/login'} element={<Login />} />
                                <Route path={'*'}  element={<Navigate to="/login" replace />} />
                            </Routes>
                        }

                    </MainLayout>
                </Suspense>
            </Router>
        </AclContextProvider>
    )
}


export const routes = [
    { path: '/dashboard', Component: Dashboard },

    { path: '/profile', Component: Profile},
    { path: '/profile/:id', Component: UserDetails},
    { path: '/profile/:id/:tab', Component: UserDetails},
    { path: '/profile/:id/:tab/:detailId', Component: UserSubDetails },

    { path: '/users', Component: Users},
    { path: '/users/:id', Component: UserDetails},
    { path: '/users/:id/:tab', Component: UserDetails},
    { path: '/users/:id/:tab/:detailId', Component: UserSubDetails },
    { path: '/users/add-user', Component: AddUser},

    { path: '/cartable', Component: Cartable},
    { path: '/cartable/:id', Component: CartableDetails},

    {path: '/wallets', Component: Wallets},

    { path: '/reports', Component: Reports},

    { path: '/reports/workspace', Component: Workspace },
    { path: '/reports/workspace/:id', Component: WorkspaceDetails },

    { path: '/reports/contract', Component: Contract },
    { path: '/reports/contract/:id', Component: ContractDetails },

    { path: '/reports/payment', Component: Payment },
    { path: '/reports/payment/:id', Component: PaymentDetails },

    { path: '/reports/affiliate', Component: Affiliates},
    // { path: '/reports/affiliate/:id', Component: AffiliateDetails},

    { path: '/reports/blocked-users', Component: BlockedUsers },
    { path: '/reports/attachments', Component: Attachments },

    { path: '/reports/addresses', Component: Addresses},

    { path: '/reports/trace', Component: TraceMain },

    { path: '/setting', Component: Settings },

    { path: '/setting/tokens', Component: Tokens },
    { path: '/setting/tokens/:id', Component: TokenDetails },
    { path: '/setting/tokens/operation', Component: TokenOperation },

    { path: '/setting/wages', Component: Fiat },

    { path: '/setting/otc', Component: Otc },
    { path: '/setting/otc/details', Component: OtcSettingDetails },

    { path: '/setting/pairs', Component: Pairs },
    { path: '/setting/roles', Component: Roles },
    { path: '/setting/robots', Component: Robots },
    { path: '/setting/currencies', Component: Currencies },
    { path: '/setting/general', Component: General },

]


export default Index;
