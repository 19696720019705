import {useWindowSize} from "../../../../../core/hooks/common/useWindowSize";
import {TABLET_SIZE} from "../../../../../core/constants/common";
import RWagesTable from "../../../../responsive/main/reports/trace/RWagesTable";
import WagesTable from "../tables/WagesTable";


const WagesTrace = () => {

    const { width } = useWindowSize()
    const Component = width > TABLET_SIZE ? WagesTable : RWagesTable

    return (
        <Component />
    )
}

export default WagesTrace
