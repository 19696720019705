import {useQuery} from "react-query";
import {filterFetch} from "../../fetch-api/get";
import {useMainContext} from "../../../contexts/main";
import {makeQueryFilters} from "../../../utils/query-maker";


const WALLET_KEYS = {
    GET_WALLETS: 'get-wallets'
}


const useGetWallets = (filters, pagination) => {

    const { lang } = useMainContext()
    const params = {...makeQueryFilters(filters, lang), ...pagination}

    return useQuery(
        WALLET_KEYS.GET_WALLETS, () => filterFetch(params, 'wallets/admin')
    )

}


export {
    useGetWallets
}
