import {useQuery} from "react-query";
import {useMainContext} from "../../../../contexts/main";
import {makeQueryFilters} from "../../../../utils/query-maker";
import {filterFetch, normalFetch} from "../../../fetch-api/get";


export const CONTRACT_KEYS = {
    LIST_CONTRACTS: 'list-contracts',
    GET_CONTRACT: 'get-contract',
    CONTRACT_PAYMENTS: 'contract-payments'
}


const useListContracts = (filters, pagination, extra, csv) => {

    const { lang } = useMainContext()
    const params = {...makeQueryFilters(filters, lang), ...pagination}
    if (csv){
        params.export = 'csv'
    }

    return useQuery(
        CONTRACT_KEYS.LIST_CONTRACTS, () => filterFetch(params, 'contracts/admin')
    )
}

const useGetContract = (id, onSuccess) => {

    return useQuery(
        [CONTRACT_KEYS.GET_CONTRACT, id], () => normalFetch(`contracts/admin/${id}`),
        {
            select: res => res?.data?.data,
            enabled: false,
            onSuccess: (res) => {
                onSuccess(res)
            }
        }
    )
}


const useGetContractPayments = (id) => {

    return useQuery(
        [CONTRACT_KEYS.CONTRACT_PAYMENTS, id], () => normalFetch(`payments/contracts/${id}`),
        {
            enabled: false,
            select: res => res?.data,
        }
    )
}

export {
    useListContracts,
    useGetContract,
    useGetContractPayments
}
