import FilterLayout from "../../../../layout/filter/FilterLayout";
import {useWindowSize} from "../../../../../core/hooks/common/useWindowSize";
import {TABLET_SIZE} from "../../../../../core/constants/common";
import TraceTable from "../tables/TraceTable";
import RTraceTable from "../../../../responsive/main/reports/trace/RTraceTable";
import {useState} from "react";
import {useGetCoinTrace} from "../../../../../core/services/react-query/report/trace";
import {
    CacheKeys,
    coinTraceFilterOptions, coinTraceSortOptions,
} from "../../../../../core/constants/filter";


const Trace = ({ currency }) => {

    const [traces, setTraces] = useState({ data: null, loading: true })
    const onQuerySuccess = res => setTraces(res)

    const { width } = useWindowSize()
    const Comp = width > TABLET_SIZE ? TraceTable : RTraceTable

    return (
        <FilterLayout
            query={useGetCoinTrace}
            onQuerySuccess={onQuerySuccess}
            options={coinTraceFilterOptions}
            cache={CacheKeys.COIN_TRACE}
            sortOptions={coinTraceSortOptions}
            extra={{ currency }}
        >
            <Comp
                data={traces}
            />
        </FilterLayout>
    )
}

export default Trace
