import {useNavigate} from "react-router-dom";
import RTableLayout from "../../../layout/RTableLayout";
import {CFlex, DText, Flex, Row} from "../../../../../styles/CommonStyles";
import Operation from "../../../../common/utils/Operation";
import CoinRow from "../../../../common/common/CoinRow";
import Text from "../../../../../core/utils/Text";
import BooleanIcon from "../../../../common/utils/BooleanIcon";
import {TradeTypeBadge} from "../../../../../styles/main/orders";
import {getTradeTypeColor} from "../../../../../core/utils/theme";
import {formatDate, formatNumber} from "../../../../../core/utils/common";
import {useMainContext} from "../../../../../core/contexts/main";


const RContractTable = ({ data }) => {

    const { lang } = useMainContext()
    const navigate = useNavigate()
    const { data: payments } = data
    const onDetailsClicked = (payment) => navigate(`/reports/payment/${payment._id}`)

    return (
        <RTableLayout
            data={data}
        >
            {payments?.data?.map((item, idx) => (
                <Row key={item._id} index={idx} cs={'50% 50%'}>
                    <CoinRow coin={{ currency: item.currency }} />
                    <Flex justify={'flex-end'}>
                        <Operation
                            onDetailsClicked={() => onDetailsClicked(item)}
                            hasDetails
                        />
                    </Flex>
                    <CFlex align={'flex-start'} justify={'space-around'} height={'150px'}>
                        <DText fontSize={'ss'} main>
                            <Text tid={'name'} /> :
                        </DText>
                        <DText fontSize={'ss'} main>
                            <Text tid={'amount'} /> :
                        </DText>
                        <DText fontSize={'ss'} main>
                            <Text tid={'needApprove'} /> :
                        </DText>
                        <DText fontSize={'ss'} main>
                            <Text tid={'payment-isActive'} /> :
                        </DText>
                        <DText fontSize={'ss'} main>
                            <Text tid={'payment-status'} /> :
                        </DText>
                        <DText fontSize={'ss'} main>
                            <Text tid={'dueDate'} /> :
                        </DText>
                    </CFlex>
                    <CFlex align={'flex-start'} justify={'space-around'} height={'150px'}>
                        <DText fontSize={'ss'} primary>
                            {item.name}
                        </DText>
                        <DText fontSize={'ss'} primary>
                            {formatNumber(item.amount)}
                        </DText>
                        <BooleanIcon bool={!!item.needApprove} />
                        <BooleanIcon bool={!!item.isActive} />
                        <TradeTypeBadge color={getTradeTypeColor(item.status)}>
                            <Text tid={item.status} />
                        </TradeTypeBadge>
                        <DText fontSize={'ss'} primary>
                            {`${formatDate(item.dueDate, 'date', lang)}-${formatDate(item.dueDate, 'time', lang)}`}
                        </DText>
                    </CFlex>
                </Row>
            ))}
        </RTableLayout>
    )
}

export default RContractTable
