import {useNavigate} from "react-router-dom";
import {contractAttributes} from "../../../../core/constants/headers";
import useGetTableIndex from "../../../../core/hooks/layout/useGetTableIndex";
import TableLayout from "../../../layout/main/TableLayout";
import {Column, Relative, Row} from "../../../../styles/CommonStyles";
import BooleanIcon from "../../../common/utils/BooleanIcon";
import DateTime from "../../../common/utils/DateTime";
import Operation from "../../../common/utils/Operation";
import NameTag from "../../../common/utils/NameTag";


const ContractTable = ({ data }) => {

    const navigate = useNavigate()
    const { data: contracts } = data
    const { headers, cs } = contractAttributes
    const { getTableIndex } = useGetTableIndex()

    const onDetailsClicked = (contract) => navigate(`/reports/contract/${contract._id}`)


    return (
        <TableLayout
            data={data}
            cs={cs}
            headers={headers}
        >
            {contracts?.data?.map((item, idx) => (
                <Relative>
                    <Row key={item._id} index={idx} cs={cs}>
                        <Column>
                            {getTableIndex(idx)}
                        </Column>
                        <Column>
                            <NameTag name={item.name}/>
                        </Column>
                        <Column>
                            <BooleanIcon bool={item.isActive} />
                        </Column>
                        <Column>
                            <BooleanIcon bool={item.isDelete} />
                        </Column>
                        <Column center>
                            <DateTime dt={item.createdAt} />
                        </Column>
                    </Row>
                    <Column operation>
                        <Operation
                            onDetailsClicked={() => onDetailsClicked(item)}
                            hasDetails
                        />
                    </Column>
                </Relative>
            ))}
        </TableLayout>
    )
}

export default ContractTable
