import FilterLayout from "../../../layout/filter/FilterLayout";
import {useWorkspaceWithdrawList} from "../../../../core/services/react-query/report/workspace";
import {useEffect, useState} from "react";
import {CacheKeys, withdrawFilterOptions} from "../../../../core/constants/filter";
import TableLayout from "../../../layout/main/TableLayout";
import {withdrawDetailAttributes, withdrawListAttributes} from "../../../../core/constants/headers";
import {Column, DText, Flex, IconWrapper, Row} from "../../../../styles/CommonStyles";
import {useWindowSize} from "../../../../core/hooks/common/useWindowSize";
import {TABLET_SIZE} from "../../../../core/constants/common";
import UserTag from "../../../common/utils/UserTag";
import useGetTableIndex from "../../../../core/hooks/layout/useGetTableIndex";
import CoinRow from "../../../common/common/CoinRow";
import {formatDate, formatNumber} from "../../../../core/utils/common";
import {TradeTypeBadge} from "../../../../styles/main/orders";
import {getTradeTypeColor} from "../../../../core/utils/theme";
import Text from "../../../../core/utils/Text";
import DateTime from "../../../common/utils/DateTime";
import {DetailsIcon} from "../../../../styles/main/reports";
import RTableLayout from "../../../responsive/layout/RTableLayout";
import {LineBreak, RespIndex} from "../../../../styles/main/MainCommonStyles";
import {useMainContext} from "../../../../core/contexts/main";
import ModalLayout from "../../../layout/main/ModalLayout";
import BooleanIcon from "../../../common/utils/BooleanIcon";


const WorkspaceWithdraw = ({ workspace, members }) => {

    const { width } = useWindowSize()

    const [withdrawList, setWithdrawList] = useState({ loading: true, data: null })
    const onQuerySuccess = res => setWithdrawList(res)
    const { headers, cs } = withdrawListAttributes

    const initial = { open: false, data: null }
    const [detailModal, setDetailModal] = useState(initial)
    const closeDetailModal = () => setDetailModal(initial)
    const openDetailModal = data => setDetailModal({ open: true, data })


    return (
        <FilterLayout
            query={useWorkspaceWithdrawList}
            onQuerySuccess={onQuerySuccess}
            extra={{ workspaceId: workspace?._id }}
            cache={CacheKeys.WITHDRAW}
            options={withdrawFilterOptions}
        >
            {width > TABLET_SIZE ?
                <TableLayout
                    headers={headers}
                    cs={cs}
                    data={withdrawList}
                    height={'unset'}
                >
                    <Desktop
                        withdrawList={withdrawList}
                        members={members}
                        onDetail={openDetailModal}
                    />
                </TableLayout>
                :
                <RTableLayout
                    data={withdrawList}
                >
                    <Mobile
                        withdrawList={withdrawList}
                        members={members}
                        onDetail={openDetailModal}
                    />
                </RTableLayout>
            }
            <ModalLayout
                width={'800px'}
                onClose={closeDetailModal}
                open={detailModal.open}
            >
                <Modal
                    withdraw={detailModal.data}
                    members={members}
                />
            </ModalLayout>
        </FilterLayout>
    )
}


const Desktop = ({ withdrawList, members, onDetail }) => {

    const { cs } = withdrawListAttributes
    const { getTableIndex } = useGetTableIndex()

    return (
        <>
            {withdrawList?.data?.data?.map((item, idx) => {
                const creator = members.find(m => m.user?._id === item.createdBy)
                return (
                    <Row key={item._id} index={idx} cs={cs}>
                        <Column>
                            {getTableIndex(idx)}
                        </Column>
                        <Column>
                            <UserTag user={creator?.user} idx={idx} />
                        </Column>
                        <Column>
                            <CoinRow
                                coin={item.data}
                            />
                        </Column>
                        <Column>
                            {formatNumber(item.data?.total)}
                        </Column>
                        <Column>
                            <TradeTypeBadge color={getTradeTypeColor(item.status)}>
                                <Text tid={item.status} />
                            </TradeTypeBadge>
                        </Column>
                        <Column center>
                            <DateTime dt={item.createdAt} />
                        </Column>
                        <Column more>
                            <IconWrapper onClick={() => onDetail(item)}>
                                <DetailsIcon size={20} />
                            </IconWrapper>
                        </Column>
                    </Row>
                )
            })}
        </>
    )
}

const Mobile = ({ withdrawList, members, onDetail }) => {

    const { lang } = useMainContext()

    return (
        <>
            {withdrawList?.data?.data?.map((item, idx) => {
                const creator = members.find(m => m.user?._id === item.createdBy)
                return (
                    <Row key={item._id} index={idx} cs={'50% 50%'}>
                        <UserTag user={creator?.user} idx={idx}/>
                        <Flex fw justify={'flex-end'}>
                            <IconWrapper onClick={() => onDetail(item)}>
                                <DetailsIcon size={20} />
                            </IconWrapper>
                        </Flex>
                        <LineBreak />
                        <div />
                        <CoinRow
                            coin={item.data}
                        />
                        <div />
                        <div />
                        <div />
                        <DText main fontSize={'ss'}>
                            <Text tid={'total'} /> :
                        </DText>
                        <DText primary fontSize={'ss'}>
                            {formatNumber(item.data?.total)}
                        </DText>
                        <div />
                        <div />
                        <DText main fontSize={'ss'}>
                            <Text tid={'status'} /> :
                        </DText>
                        <Flex justify={'flex-start'}>
                            <TradeTypeBadge color={getTradeTypeColor(item.status)}>
                                <Text tid={item.status} />
                            </TradeTypeBadge>
                        </Flex>
                        <div />
                        <div />
                        <DText main fontSize={'ss'}>
                            <Text tid={'date'} /> :
                        </DText>
                        <DText primary fontSize={'ss'}>
                            {`${formatDate(item.createdAt, 'date', lang)}-${formatDate(item.createdAt, 'time', lang)}`}
                        </DText>
                    </Row>
                )})
            }

        </>
    )
}

const Modal = ({ withdraw, members }) => {

    const { cs, headers } = withdrawDetailAttributes
    const [list, setList] = useState([])
    useEffect(() => {
        const temp = []
        members.forEach(mem => {
            let x = {}
            x.user = mem.user
            x.approved = withdraw.approveObject[mem.user?._id]
            x.share = withdraw.data.shares.find(s => s.userId === mem.user?._id)?.amount
            temp.push(x)
        })
        setList(temp)
    }, [withdraw, members])

    return (
        <>
            <Row cs={cs} header>
                {headers.map(head => (
                    <Column center={head.title !== 'user'}>
                        <Text tid={head.title} />
                    </Column>
                ))}
            </Row>
            {list.map((item, idx) => (
                <Row cs={cs} key={item.user?._id} index={idx}>
                    <Column>
                        <UserTag user={item.user} idx={idx} width={'unset'} />
                    </Column>
                    <Column center>
                        <BooleanIcon bool={item.approved !== undefined} />
                    </Column>
                    <Column center>
                        {item.approved === undefined ?
                            '-'
                            :
                            item.approved === false ?
                                <BooleanIcon bool={false} />
                                :
                                <DateTime dt={item.approved} />
                        }
                    </Column>
                    <Column center>
                        {formatNumber(item.share)}
                    </Column>
                </Row>
            ))}
        </>
    )
}



export default WorkspaceWithdraw
