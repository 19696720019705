import {useEffect, useState} from "react";
import {normalFetch} from "../../../../core/services/fetch-api/get";
import {ContractMemberRow} from "../../../../styles/main/reports";
import {DText} from "../../../../styles/CommonStyles";
import Text from "../../../../core/utils/Text";
import {LineBreak} from "../../../../styles/main/MainCommonStyles";
import UserTag from "../../../common/utils/UserTag";
import WorkspaceTag from "../../../common/utils/WorkspaceTag";


const ContractMembers = ({ members }) => {

    const [items, setItems] = useState([])

    useEffect(() => {
        const fn = async () => {
            let newItems = []
            for (const member of members) {
                let path = ''
                if (member.type === 'user') path = `users/${member._id}`
                if (member.type === 'workspace') path = `workspaces/admin/${member._id}`
                const item = await normalFetch(path)
                newItems.push({
                    type: member.type,
                    user: item?.data?.data
                })
            }
            setItems(newItems)
        }
        fn()

    }, [members])


    return (
        <>
            <ContractMemberRow>
                {headers.map(head => (
                    <DText main>
                        <Text tid={head} />
                    </DText>
                ))}
            </ContractMemberRow>
            <LineBreak />
            {items.map((item, idx) => (
                <ContractMemberRow>
                    {item.type === 'user' ?
                        <UserTag user={item.user} idx={idx} />
                        :
                        <WorkspaceTag workspace={item.user} idx={idx} />
                    }
                    <DText primary fontSize={'s'}>
                        <Text tid={item.type} />
                    </DText>
                    <DText primary fontSize={'s'}>
                        {item.user.code}
                    </DText>
                </ContractMemberRow>
            ))}
        </>
    )
}

const headers = [
    'member', 'type', 'code'
]

export default ContractMembers
