import {useMutation, useQuery} from "react-query";
import {normalFetch} from "../../fetch-api/get";
import {putApi} from "../../fetch-api/put";
import {useQueryContext} from "../../../contexts/query";


const PARAM_KEYS = {
    GET_PARAMS: 'get-params',
    SET_PARAMS: 'set-params'
}


const useGetParams = () => {

    return useQuery(
        PARAM_KEYS.GET_PARAMS, () => normalFetch('settings/params'),
        {
            select: res => res?.data?.data,
            cacheTime: 5 * 60 * 100,
            staleTime: 5 * 60 * 100
        }
    )
}


const useSetParams = () => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        PARAM_KEYS.SET_PARAMS, (data) => putApi(data, 'settings/params'),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(PARAM_KEYS.GET_PARAMS)
                setToast({
                    show: true,
                    message: 'operation-success'
                })
            }
        }
    )
}


export {
    useGetParams,
    useSetParams
}
