import ListLayout from "../../../components/layout/main/ListLayout";
import {PageCard} from "../../../styles/CommonStyles";
import FilterLayout from "../../../components/layout/filter/FilterLayout";
import {attachmentFilterOptions, CacheKeys} from "../../../core/constants/filter";
import {useListAttachments} from "../../../core/services/react-query/report/attachments";
import {useWindowSize} from "../../../core/hooks/common/useWindowSize";
import {useState} from "react";
import {TABLET_SIZE} from "../../../core/constants/common";
import AttachmentsTable from "../../../components/main/reports/attachments/AttachmentsTable";
import RAttachmentsTable from "../../../components/responsive/main/reports/attachments/RAttachmentsTable";



const Attachments = () => {

    const { width } = useWindowSize()

    const [attachments, setAttachments] = useState({loading: true, data: null})
    const onUserQuery = res => setAttachments(res)

    const Component = width > TABLET_SIZE ? AttachmentsTable : RAttachmentsTable

    return (
        <ListLayout>
            <PageCard>
                <FilterLayout
                    query={useListAttachments}
                    options={attachmentFilterOptions}
                    onQuerySuccess={onUserQuery}
                    cache={CacheKeys.ATTACHMENTS}
                    // sortOptions={contractSortOptions}
                >
                    <Component data={attachments} />
                </FilterLayout>
            </PageCard>
        </ListLayout>
    )
}

export default Attachments
