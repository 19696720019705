import {BalanceTag, CFlex, DText, Flex} from "../../../../styles/CommonStyles";
import {useGetAUser} from "../../../../core/services/react-query/user";
import {useEffect, useState} from "react";
import DetailCard from "../../../layout/main/details/DetailCard";
import DetailItem from "../../../layout/main/details/DetailItem";
import UserTag from "../../../common/utils/UserTag";
import BooleanIcon from "../../../common/utils/BooleanIcon";
import {Link} from "react-router-dom";
import CoinRow from "../../../common/common/CoinRow";
import {formatDate, formatNumber} from "../../../../core/utils/common";
import Text from "../../../../core/utils/Text";
import PaymentMembers from "./PaymentMembers";
import {LineBreak} from "../../../../styles/main/MainCommonStyles";
import {useMainContext} from "../../../../core/contexts/main";
import {TradeTypeBadge} from "../../../../styles/main/orders";
import {getTradeTypeColor} from "../../../../core/utils/theme";
import PaymentApproveList from "./PaymentApproveList";
import PaymentEditHistory from "./PaymentEditHistory";



const PaymentDetailView = ({ payment }) => {


    const { lang } = useMainContext()
    const { data: creator, refetch: getCreator } = useGetAUser(payment?.createdBy)
    const [options, setOptions] = useState({})

    useEffect(() => {
        if (payment) {
            getCreator()
            const _options = {}
            _options.isActive = payment.isActive
            _options.isSafe = payment.isSafe
            _options.isEditableBySender = payment.isEditableBySender
            _options.isCancelableBySender = payment.isCancelableBySender
            _options.needApprove = payment.needApprove
            _options.approveExpireDate = payment.approveExpireDate
            setOptions(_options)
        }
    }, [payment])


    return (
        <CFlex fw>
            <DetailCard>
                <Flex fw justify={'space-around'}>
                    <Flex>
                        <DText main margin={'0 10px'}>
                            <Text tid={'dueDate'} />:
                        </DText>
                        <TradeTypeBadge color={getTradeTypeColor('individual')}>
                            {`${formatDate(payment?.dueDate, 'date', lang)}-${formatDate(payment?.dueDate, 'time', lang)}`}
                        </TradeTypeBadge>
                    </Flex>
                    <Flex>
                        <DText main margin={'0 10px'}>
                            <Text tid={'status'} />:
                        </DText>
                        <TradeTypeBadge color={getTradeTypeColor(payment?.status)}>
                            <Text tid={payment?.status} />
                        </TradeTypeBadge>
                    </Flex>
                </Flex>
            </DetailCard>

            {/* details */}
            <DetailCard
                header={'details'}
                date={payment?.createdAt}
            >
                <Flex fw wrap justify={'space-between'}>
                    <DetailItem prefix={'creator'} suffix={<UserTag user={creator} />} />
                    <DetailItem prefix={'contract'} suffix={
                        <Link to={`/reports/contract/${payment?.contractId}`}>
                            <DText primary>
                                {payment?.contractId}
                            </DText>
                        </Link>
                    } />
                    <DetailItem prefix={'name'} suffix={payment?.name} />
                    <DetailItem prefix={'is-active'} suffix={<BooleanIcon bool={payment?.isActive} />} />
                </Flex>
                {payment?.description &&
                    <DetailItem prefix={'description'} suffix={payment?.description} />
                }
            </DetailCard>

            <Flex fw justify={'space-between'} align={'stretch'} wrap>
                {/* more details */}
                <DetailCard
                    header={'options'}
                    width={'49%'}
                >
                    {Object.keys(options).map(key => {
                        const value = options[key]
                        return (
                            <>
                                <Flex fw style={{ padding: '8px 0' }}>
                                    <Flex width={'50%'} justify={'flex-start'}>
                                        <DText main fontSize={'s'}>
                                            <Text tid={key}/> :
                                        </DText>
                                    </Flex>
                                    <Flex width={'50%'} justify={'flex-start'}>
                                        {key === 'approveExpireDate' ?
                                            <DText primary>
                                                {`${formatDate(value, 'date', lang)}-${formatDate(value, 'time', lang)}`}
                                            </DText>
                                            :
                                            <BooleanIcon bool={value} />
                                        }
                                    </Flex>
                                </Flex>
                                <LineBreak />
                            </>
                        )
                    })}
                </DetailCard>

                {/* currency and shares */}
                <DetailCard
                    header={'currency-and-shares'}
                    width={'49%'}
                >
                    <BalanceTag style={{ justifyContent: 'space-around', marginBottom: '20px' }}>
                        <CoinRow coin={{ currency: payment?.currency }} />
                        <Flex>
                            <DText main>
                                <Text tid={'amount'} />:
                            </DText>
                            <DText primary margin={'0 4px'}>
                                {formatNumber(payment?.amount)}
                            </DText>
                        </Flex>
                        <Flex>
                            <DText main>
                                <Text tid={'amountBlocked'} />:
                            </DText>
                            <DText primary margin={'0 4px'}>
                                {formatNumber(payment?.amountBlocked)}
                            </DText>
                        </Flex>
                    </BalanceTag>
                    <PaymentMembers members={payment?.shares} />
                </DetailCard>
            </Flex>

            <Flex fw justify={'space-between'} align={'stretch'} wrap>
                {/* approve object */}
                <DetailCard
                    width={'49%'}
                    header={'approve-list'}
                >
                    <PaymentApproveList data={payment?.approveObject} />
                </DetailCard>

                {/* edit history */}
                <DetailCard
                    width={'49%'}
                    header={'edit-history'}
                >
                    <PaymentEditHistory history={payment?.editHistory} />
                </DetailCard>
            </Flex>
        </CFlex>
    )
}

export default PaymentDetailView
