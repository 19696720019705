import Tabbar from "../../../components/common/tabs/Tabbar"
import ListLayout from "../../../components/layout/main/ListLayout"
import AffiliateList from "../../../components/main/reports/affiliates/list/AffiliateList"
import AffiliateTrace from "../../../components/main/reports/affiliates/trace/AffiliateTrace"
import { PageCard } from "../../../styles/CommonStyles"
import {useMainContext} from '../../../core/contexts/main'


const Affiliates = () => {

    /**
     * tabs => 1) affiliate-list 2) affiliate-trace
     */

    const {affiliateTab, setAffiliateTab} = useMainContext()

    return (
        <ListLayout>
            <PageCard>
                <AffiliateList />
            </PageCard>
        </ListLayout>
    )

}


export default Affiliates
