import {useParams} from "react-router-dom";
import {useState} from "react";
import DetailLayout from "../../../components/layout/main/details/DetailLayout";
import ListLayout from "../../../components/layout/main/ListLayout";
import {useGetPayment} from "../../../core/services/react-query/report/payment";
import PaymentDetailView from "../../../components/main/reports/payment/PaymentDetailView";


const PaymentDetails = () => {

    const params = useParams()
    const [payment, setPayment] = useState()


    return (
        <DetailLayout
            query={useGetPayment}
            onQuerySuccess={res => setPayment(res)}
            id={params.id}
        >
            <ListLayout>
                <PaymentDetailView
                    payment={payment}
                />
            </ListLayout>
        </DetailLayout>
    )
}

export default PaymentDetails
