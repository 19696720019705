import ListLayout from "../../../components/layout/main/ListLayout";
import {PageCard} from "../../../styles/CommonStyles";
import FilterLayout from "../../../components/layout/filter/FilterLayout";
import {CacheKeys, workspaceFilterOptions, workspaceSortOptions} from "../../../core/constants/filter";
import {useState} from "react";
import {useListWorkspaces} from "../../../core/services/react-query/report/workspace";
import {useWindowSize} from "../../../core/hooks/common/useWindowSize";
import {TABLET_SIZE} from "../../../core/constants/common";
import WorkspaceTable from "../../../components/main/reports/workspace/WorkspaceTable";
import RWorkspaceTable from "../../../components/responsive/main/reports/workspace/RWorkspaceTable";


const Workspace = () => {

    const { width } = useWindowSize()

    const [workspaces, setWorkspaces] = useState({loading: true, data: null})
    const onUserQuery = res => setWorkspaces(res)

    const Component = width > TABLET_SIZE ? WorkspaceTable : RWorkspaceTable

    return (
        <ListLayout>
            <PageCard>
                <FilterLayout
                    query={useListWorkspaces}
                    options={workspaceFilterOptions}
                    onQuerySuccess={onUserQuery}
                    cache={CacheKeys.WORKSPACE}
                    sortOptions={workspaceSortOptions}
                >
                    <Component data={workspaces} />
                </FilterLayout>
            </PageCard>
        </ListLayout>
    )
}

export default Workspace
