import {useAclContext} from "../../../contexts/acl";
import {useState} from "react";
import {bankAccountOperations} from "../../../constants/operation";


const useBankAccounts = () => {

    const { permissions } = useAclContext()
    const hasWriteAccess = permissions?.user?.write

    const actionInitial = {
        show: false,
        action: null,
        bank: null
    }
    const [actionMode, setActionMode] = useState(actionInitial)
    const closeActionMode = () => setActionMode(actionInitial)

    const onOptionClicked = (idx, bank, _id) => {
        setActionMode({
            show: true,
            action: bankAccountOperations[idx],
            bank,
            _id
        })
    }

    return {
        hasWriteAccess,
        actionMode,
        closeActionMode,
        onOptionClicked
    }
}

export default useBankAccounts
