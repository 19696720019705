import {useCurrencies, useGetConfigItems, useSetConfigItems} from "../../../../services/react-query/setting";
import {useEffect, useState} from "react";
import {deepCopy} from "../../../../utils/common";
import {useQueryContext} from "../../../../contexts/query";
import {useNavigate} from "react-router-dom";


const useOtcSetting = () => {

    const navigate = useNavigate()
    const { setToast } = useQueryContext()
    const { data } = useGetConfigItems()
    const { data: availableCoins } = useCurrencies()
    const {mutate: updateOtcSetting, isLoading: updatingOtc, isSuccess: otcSettingUpdated} = useSetConfigItems()

    const [otcSetting, setOtcSetting] = useState(null)
    const [editMode, setEditMode] = useState(false)
    const [baseInputError, setBaseInputError] = useState(false)

    const resetOtcSetting = () => setOtcSetting(deepCopy(data))

    useEffect(() => {
        data && resetOtcSetting()
    }, [data])

    useEffect(() => {
        if (otcSetting?.third.base && baseInputError) setBaseInputError(false)
    }, [otcSetting])

    useEffect(() => {
        if (otcSettingUpdated) setEditMode(false)
    }, [otcSettingUpdated])

    const enterEditMode = () => setEditMode(true)
    const exitEditMode = () => {
        setEditMode(false)
        resetOtcSetting()
    }

    const onDetailsChange = (v, type) => {
        const newOtcSetting = deepCopy(otcSetting)
        newOtcSetting.third[type] = v
        setOtcSetting(newOtcSetting)
    }

    const onCoinStateChange = (coin, isActive) => {
        const newOtcSetting = deepCopy(otcSetting)
        const activeCoins = newOtcSetting.third?.coins

        if (isActive) {
            newOtcSetting.third.coins = activeCoins.filter(item => item !== coin)
        }else {
            newOtcSetting.third.coins.push(coin)
        }
        setOtcSetting(newOtcSetting)
    }

    const changeAllSelectedState = (isActive) => {
        const newOtcSetting = deepCopy(otcSetting)

        if (isActive) {
            newOtcSetting.third.coins = []
        }else {
            const allCoins = availableCoins.map(item => item.id)
            newOtcSetting.third.coins = allCoins
        }
        setOtcSetting(newOtcSetting)
    }

    const onDetailsClicked = () => {
        navigate('/setting/otc/details')
    }

    const onSubmitChanges = () => {
        if (!otcSetting.third.base) {
            setBaseInputError(true)
            setToast({
                message: 'fill-inputs-error',
                show: true, isError: true
            })
        }else {
            otcSetting.third.base = otcSetting.third.base.toLowerCase()
            updateOtcSetting({data: otcSetting})
        }
    }

    return {
        otcSetting,
        editMode,
        enterEditMode,
        exitEditMode,
        onSubmitChanges,
        updatingOtc,
        onDetailsClicked,
        onDetailsChange,
        onCoinStateChange,
        changeAllSelectedState,
        baseInputError
    }
}

export default useOtcSetting
