import RTableLayout from "../../../layout/RTableLayout";
import {CFlex, Column, DText, Flex, Row} from "../../../../../styles/CommonStyles";
import Text from "../../../../../core/utils/Text";
import {useMainContext} from "../../../../../core/contexts/main";
import CoinRow from "../../../../common/common/CoinRow";
import {addressAttributes} from "../../../../../core/constants/headers";
import NameTag from "../../../../common/utils/NameTag";
import UserLink from "../../../../common/utils/UserLink";
import {formatDate} from "../../../../../core/utils/common";


const RAddressesTable = ({
    data
}) => {

    const { data: addresses } = data
    const { lang } = useMainContext()

    const { headers } = addressAttributes

    return (
        <RTableLayout
            data={data}
        >
            {addresses?.data?.map((address, idx) => {

                return (
                    <Row
                        key={address._id}
                        index={idx}
                        cs={'50% 50%'}
                    >
                        <Column>
                            <CoinRow coin={{ currency: address.currency }} />
                        </Column>
                        <div />
                        <CFlex align={'flex-start'} justify={'space-around'} height={'140px'} margin={'10px 0 0 0'}>
                            {headers.map(head => (
                                <DText fontSize={'ss'} main>
                                    <Text tid={head.title} /> :
                                </DText>
                            ))}
                        </CFlex>
                        <CFlex align={'flex-start'} justify={'space-around'} height={'140px'} margin={'10px 0 0 0'}>
                            <NameTag name={address.name} />
                            <UserLink _id={address.user?._id} name={address.user?.email} />
                            <CoinRow coin={{ currency: address.currency }} />
                            <NameTag name={address.address} />
                            <DText primary fontSize={'ss'}>
                                {`${formatDate(address.createdAt, 'date', lang)}-${formatDate(address.createdAt, 'time', lang)}`}
                            </DText>
                        </CFlex>
                    </Row>
                )
            })}
        </RTableLayout>
    )
}


export default RAddressesTable
