import {useMutation, useQuery} from "react-query";
import {normalFetch} from "../../fetch-api/get";
import {putApi} from "../../fetch-api/put";
import {useQueryContext} from "../../../contexts/query";


const BANK_KEYS = {
    GET_BANKS: 'get-banks',
    SET_BANKS: 'set-banks'
}

const useGetAvailableBanks = () => {

    return useQuery(
        BANK_KEYS.GET_BANKS, () => normalFetch('settings/banks'),
        {
            select: res => res?.data?.data
        }
    )
}


const useSetAvailableBanks = () => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        BANK_KEYS.SET_BANKS, (data) => putApi(data, 'settings/banks'),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(BANK_KEYS.GET_BANKS)
                setToast({
                    message: 'operation-success', show: true
                })
            }
        }
    )
}



export {
    useGetAvailableBanks,
    useSetAvailableBanks
}