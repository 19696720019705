import {useEffect, useState} from "react";
import {normalFetch} from "../../../../core/services/fetch-api/get";
import {DText, Flex} from "../../../../styles/CommonStyles";
import {ClipLoader} from "react-spinners";
import NoData from "../../../common/utils/NoData";
import UserTag from "../../../common/utils/UserTag";
import {PaymentChangeArrow, PaymentChangeTag} from "../../../../styles/main/reports";
import Text from "../../../../core/utils/Text";
import {LineBreak} from "../../../../styles/main/MainCommonStyles";


const PaymentEditHistory = ({history}) => {

    const [items, setItems] = useState([])

    useEffect(() => {
        const fn = async () => {
            let newItems = []

            for (const h of history) {
                const item = await normalFetch(`users/${h.by}`)
                newItems.push({
                    user: item?.data?.data,
                    changes: h.changes ? h.changes : []
                })
            }
            setItems(newItems)
        }
        fn()

    }, [history])

    return (
        <>
            {items.length ?
                items.map((item, idx) => (
                    <>
                        <UserTag user={item.user} idx={idx} />
                        <Flex fw wrap justify={'flex-start'}>
                            {item.changes?.map(change => (
                                <PaymentChangeTag>
                                    <Flex fw>
                                        <DText main fontSize={'s'} margin={'0 4px'}>
                                            <Text tid={change.key} />:
                                        </DText>
                                        <DText primary>
                                            {typeof change.old === 'boolean' ?
                                                (!change.old ? 'false' : 'true')
                                                :
                                                change.old
                                            }
                                        </DText>
                                        <PaymentChangeArrow size={16} />
                                        <DText primary>
                                            {typeof change.new === 'boolean' ?
                                                (!change.new ? 'false' : 'true')
                                                :
                                                change.new
                                            }
                                        </DText>
                                    </Flex>
                                </PaymentChangeTag>
                            ))}
                        </Flex>
                        <LineBreak />
                    </>
                ))
                :
                <Flex fw height={'200px'}>
                    { history?.length ?
                        <ClipLoader color={'#ffc800'}/>
                        :
                        <NoData />
                    }
                </Flex>
            }
        </>
    )
}

export default PaymentEditHistory
